import React, { Component } from "react";
import { Col, Form, FormGroup, Input, Label } from "reactstrap";

import { FilesSection } from "../Files/files";
import InputDate from "../InputDate/inputDate";
import Radio from "../Radio/radio";

class CarpentryDetails extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.labels = Object.assign({}, require("./labels.json"));

    this.state = {
      details: {
        // color: '',
        // type: undefined,
        // otherType: '',
        grip: undefined,
        construction: "WOOD",
        kitchen: undefined,
        kitchenDate: "",
        bath: undefined,
        bathDate: "",
        filesData: { files: [] },
      },
    };

    this.isTouched = {
      // color: undefined,
      // type: undefined,
      // otherType: undefined,
      grip: undefined,
      construction: undefined,
      kitchen: undefined,
      kitchenDate: undefined,
      bath: undefined,
      bathDate: undefined,
      notes: undefined,
    };
  }

  loadData(data) {
    this.setState((prevState) => {
      let details = Object.assign({}, prevState.details);
      details = data;
      this.props.onChange("carpentryDetails", details);
      return { details };
    });
  }

  handleChange(event) {
    let value = event.target.value;
    let id = event.target.id;

    this.setState((prevState) => {
      let details = Object.assign({}, prevState.details);
      details[id] = value;
      this.props.onChange("carpentryDetails", details);
      return { details };
    });
  }

  handleFiles(allFiles) {
    this.setState((prevState) => {
      let details = Object.assign({}, prevState.details);
      if (!details.filesData) {
        details.filesData = { files: [] };
      }
      details.filesData = {
        files: allFiles,
      };
      this.props.onChange("carpentryDetails", details);
      return { details };
    });
  }

  render() {
    const disabled = false;
    const labels = this.labels;

    const isBathDateValid =
      this.state.details.bathDate !== "" &&
      this.state.details.bathDate !== undefined;
    const isBathDateInvalid =
      this.isTouched["bathDate"] === true && this.state.details.bathDate === "";

    const isKitchenDateValid =
      this.state.details.kitchenDate !== "" &&
      this.state.details.kitchenDate !== undefined;
    const isKitchenDateInvalid =
      this.isTouched["kitchenDate"] === true &&
      this.state.details.kitchenDate === "";

    return (
      <div>
        <Form style={{ marginTop: "20px" }}>
          <FormGroup row>
            <Col md={{ size: 2 }}>
              <Radio
                id="grip"
                label={labels["grip"]}
                options={labels["gripOptions"]}
                value={this.state.details.grip}
                onChange={this.handleChange}
                disabled={disabled}
              ></Radio>
            </Col>
            <Col md={{ size: 2 }}>
              <Radio
                id="construction"
                label={labels["construction"]}
                options={labels["constructionOptions"]}
                value={this.state.details.construction}
                onChange={this.handleChange}
                disabled={disabled}
              ></Radio>
            </Col>
            <Col md={{ size: 2 }}>
              <Radio
                id="kitchen"
                label={labels["kitchen"]}
                options={labels["kitchenOptions"]}
                value={this.state.details.kitchen}
                onChange={this.handleChange}
                disabled={disabled}
              ></Radio>
              {this.state.details.kitchen === "FUTURE" ? (
                <InputDate
                  id="kitchenDate"
                  label={labels["date"]}
                  value={this.state.details.kitchenDate}
                  onChange={this.handleChange}
                  disabled={disabled}
                  valid={isKitchenDateValid}
                  invalid={isKitchenDateInvalid}
                ></InputDate>
              ) : null}
            </Col>
            <Col md={{ size: 2 }}>
              <Radio
                id="bath"
                label={labels["bath"]}
                options={labels["bathOptions"]}
                value={this.state.details.bath}
                onChange={this.handleChange}
                disabled={disabled}
              ></Radio>
              {this.state.details.bath === "FUTURE" ? (
                <InputDate
                  id="bathDate"
                  label={labels["date"]}
                  value={this.state.details.bathDate}
                  onChange={this.handleChange}
                  disabled={disabled}
                  valid={isBathDateValid}
                  invalid={isBathDateInvalid}
                ></InputDate>
              ) : null}
            </Col>
            <Col md={{ size: 3 }}>
              <Radio
                id="butcher"
                label={labels["butcher"]}
                options={labels["butcherOptions"]}
                value={this.state.details.butcher}
                onChange={this.handleChange}
                disabled={disabled}
              ></Radio>
            </Col>
          </FormGroup>
          <FormGroup style={{ width: "40%" }}>
            <Label>{labels["notes"]}</Label>
            <Input
              id="notes"
              type="textarea"
              value={this.state.details.notes}
              rows={5}
              onChange={this.handleChange}
            />
          </FormGroup>
        </Form>
        <FilesSection
          filesData={this.state.details.filesData}
          handleFiles={this.handleFiles}
        />
      </div>
    );
  }
}

export default CarpentryDetails;
