import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const GoogleBtn = (props) => {
  const [state, setState] = useState({
    isLogined: false,
    accessToken: "",
  });

  useEffect(() => {
    // eslint-disable-next-line no-undef
    google.accounts.id.initialize({
      client_id: CLIENT_ID,
      callback: handleLogin,
    });

    // eslint-disable-next-line no-undef
    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
    });
  }, []);

  const handleLogin = (response) => {
    let userProfile = jwt_decode(response.credential);
    console.log(userProfile);

    if (
      [
        "omri@onezero.co.il",
        "nati@onezero.co.il",
        "ozbublil@gmail.com",
        "shayroubeni@gmail.com",
        "shay.ru12341@gmail.com",
        "yarin123497@gmail.com",
        "yardenshayish@gmail.com",
        "bublil047@gmail.com",
        "idanbublil132@gmail.com",
        "yardensherut@gmail.com",
      ].includes(userProfile.email)
    ) {
      setState({
        isLogined: true,
        accessToken: response.credential,
      });
      props.onChange(userProfile.email, response.credential);
    }
  };

  return (
    <div>
      <div
        id="signInDiv"
        style={{ display: "flex", justifyContent: "center" }}
      ></div>
      {state.isLogined ? <div>Logout</div> : <div id="signInDiv"></div>}
      {/* { this.state.accessToken ? <h5>Your Access Token: <br/><br/> { this.state.accessToken }</h5> : null } */}
    </div>
  );
};

export default GoogleBtn;
