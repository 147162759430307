import React, { Component } from "react";
import { Col, CustomInput, Form, FormGroup, Label } from "reactstrap";

import "./styles.css";

import api from "../../Services/api";
import InputText from "../InputText/inputText";

import { FilesSection } from "../Files/files";

class FinishDetails extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.handleSignatureChange = this.handleSignatureChange.bind(this);
    this.openSignatureDialog = this.openSignatureDialog.bind(this);
    this.closeSignatureDialog = this.closeSignatureDialog.bind(this);
    this.print = this.print.bind(this);
    this.downloadPDF = this.downloadPDF.bind(this);
    this.downloadState = this.downloadState.bind(this);

    this.labels = Object.assign({}, require("./labels.json"));

    this.sellers = [];
    this.surveyors = [];
    this.installers = [];

    this.state = {
      details: {
        seller_id: undefined,
        surveyor_id: undefined,
        installer_id: undefined,
        status: undefined,
        payment_contact: "",
        filesData: { files: [] },
      },
      customerSignature: undefined,
    };
  }

  loadData(sellerId, surveyorId, status, paymentContact, finishData) {
    this.setState((prevState) => {
      let details = Object.assign({}, prevState.details);
      details = finishData;
      try {
        details["seller_id"] = parseInt(sellerId);
        details["surveyor_id"] = parseInt(surveyorId);
        details["status"] = status;
        details["payment_contact"] = paymentContact;
      } catch (error) {
        details = prevState.details;
      }
      this.props.onChange("finish", details);
      return { details };
    });
  }

  componentDidUpdate() {}
  componentDidMount() {
    let sellersPromise = api.sellers.get({});
    let surveyorsPromise = api.surveyors.get({});
    let installersPromise = api.installers.get({});

    Promise.all([sellersPromise, surveyorsPromise, installersPromise])
      .then((values) => {
        this.sellers = values[0].data.sellers;
        this.surveyors = values[1].data.surveyors;
        this.installers = values[2].data.installers;

        if (this.state.details.seller_id == undefined) {
          // this.state.seller_id = null

          let sellerId = this.sellers.filter((seller) => {
            // console.log(seller.email);
            return seller.email == localStorage.getItem("email");
          });

          if (sellerId.length != 0) {
            sellerId = parseInt(sellerId[0].id);
          }
          console.log("seller id is: ", sellerId);
          this.setState((prevState) => {
            let details = Object.assign({}, prevState.details);
            details["seller_id"] = sellerId;

            this.props.onChange("finish", details);

            return { details };
          });
        }
      })
      .catch((error) => {})
      .finally(() => {
        // always executed
      });
  }

  handleChange(event) {
    let value = event.target.value;
    let id = event.target.id;

    this.setState((prevState) => {
      let details = Object.assign({}, prevState.details);
      if (id == "surveyor_id" || id == "seller_id" || id == "installer_id") {
        if (value == undefined) {
          value = null;
        } else {
          details[id] = parseInt(value);
        }
      } else {
        details[id] = value;
      }

      this.props.onChange("finish", details);

      return { details };
    });
  }

  handleFiles(allFiles) {
    this.setState((prevState) => {
      let details = Object.assign({}, prevState.details);
      if (!details.filesData) {
        details.filesData = { files: [] };
      }
      details.filesData = {
        files: allFiles,
      };
      this.props.onChange("finish", details);
      return { details };
    });
  }

  handleSignatureChange(event) {
    this.setState({ customerSignature: event });
    this.closeSignatureDialog();
  }

  openSignatureDialog = () => {
    this.setState({ showSignatureDialog: true });
  };
  closeSignatureDialog = () => {
    this.setState({ showSignatureDialog: false });
  };

  print = () => {
    this.setState({ formCanvasDataUrl: true });
  };
  downloadPDF = (csvURL) => {
    let tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "Order" + Date.now().toString() + ".pdf");
    tempLink.click();
    tempLink.remove();
    this.setState({ formCanvasDataUrl: null });
  };

  downloadState = () => {
    let exportObj = this.state;
    let str =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(exportObj));
    let tempLink = document.createElement("a");
    tempLink.href = str;
    tempLink.setAttribute(
      "download",
      "Order" + Date.now().toString() + ".json",
    );
    tempLink.click();
    tempLink.remove();
  };

  render() {
    const disabled = false;
    const labels = this.labels;

    let sellersOptionsElements = this.sellers.map((seller, index) => {
      return (
        <option key={index} value={seller.id}>
          {seller.name}
        </option>
      );
    });

    let surveyorsOptionsElements = this.surveyors.map((surveyor, index) => {
      return (
        <option key={index} value={surveyor.id}>
          {surveyor.name}
        </option>
      );
    });

    let installersOptionsElements = this.installers.map((installer, index) => {
      return (
        <option key={index} value={installer.id}>
          {installer.name}
        </option>
      );
    });

    let statusOptionsElements = labels.statusOptions.map((option, index) => {
      return (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      );
    });

    return (
      <div>
        <Form style={{ marginTop: "20px" }}>
          <FormGroup row>
            <Col md={{ size: 2 }}>
              <Label>{labels.seller}</Label>

              <CustomInput
                type="select"
                id="seller_id"
                name="seller_idSelect"
                onChange={this.handleChange}
                value={this.state.details.seller_id}
              >
                <option value={undefined}>{labels.choose}</option>
                {sellersOptionsElements}
              </CustomInput>
            </Col>
            <Col md={{ size: 2 }}>
              <Label>{labels.surveyor}</Label>

              <CustomInput
                type="select"
                id="surveyor_id"
                name="surveyor_idSelect"
                onChange={this.handleChange}
                value={this.state.details.surveyor_id}
              >
                <option value={undefined}>{labels.choose}</option>
                {surveyorsOptionsElements}
              </CustomInput>
            </Col>
            <Col md={{ size: 2 }}>
              <Label>{labels.installer}</Label>

              <CustomInput
                type="select"
                id="installer_id"
                name="installer_idSelect"
                onChange={this.handleChange}
                value={this.state.details.installer_id}
              >
                <option value={undefined}>{labels.choose}</option>
                {installersOptionsElements}
              </CustomInput>
            </Col>
            <Col md={{ size: 2 }}>
              <InputText
                id="payment_contact"
                value={this.state.details.payment_contact}
                onChange={this.handleChange}
                label={labels.paymentContact}
              ></InputText>
            </Col>

            <Col md={{ size: 2 }}>
              <Label>{labels.status}</Label>

              <CustomInput
                type="select"
                id="status"
                name="statusSelect"
                onChange={this.handleChange}
                value={this.state.details.status}
              >
                <option value={undefined}>{labels.choose}</option>
                {statusOptionsElements}
              </CustomInput>
            </Col>
          </FormGroup>
        </Form>
        <FilesSection
          filesData={this.state.details.filesData}
          handleFiles={this.handleFiles}
        />
      </div>
    );
  }
}

export default FinishDetails;
