import React, { Component } from "react";
import { Col, Form, FormGroup, Input, Label } from "reactstrap";

import { FilesSection } from "../Files/files";
import InputText from "../InputText/inputText";
import Radio from "../Radio/radio";

class Stove extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.labels = Object.assign({}, require("./labels.json"));

    this.state = {
      details: {
        type: undefined,
        size: undefined,
        installationType: "REGULAR",
        notes: undefined,
        filesData: { files: [] },
      },
    };
  }

  loadData(data) {
    this.setState((prevState) => {
      let details = Object.assign({}, prevState.details);
      details = data;
      this.props.onChange("stove", details);
      return { details };
    });
  }

  handleChange(event) {
    let value = event.target.value;
    let id = event.target.id;

    this.setState((prevState) => {
      let details = Object.assign({}, prevState.details);
      details[id] = value;
      this.props.onChange("stove", details);
      return { details };
    });
  }

  handleFiles(allFiles) {
    this.setState((prevState) => {
      let details = Object.assign({}, prevState.details);
      if (!details.filesData) {
        details.filesData = { files: [] };
      }
      details.filesData = {
        files: allFiles,
      };
      this.props.onChange("stove", details);
      return { details };
    });
  }

  render() {
    const disabled = false;
    const labels = this.labels;

    return (
      <div>
        <Form style={{ marginTop: "20px" }}>
          <FormGroup row>
            <Col md={{ size: 2 }}>
              <Radio
                id="type"
                label={labels["type"]}
                options={labels["typeOptions"]}
                value={this.state.details.type}
                onChange={this.handleChange}
                disabled={disabled}
              ></Radio>
            </Col>

            <Col md={{ size: 2 }}>
              <Radio
                id="size"
                label={labels["size"]}
                options={labels["sizeOptions"]}
                value={this.state.details.size}
                onChange={this.handleChange}
                disabled={disabled}
              ></Radio>
              {this.state.details.size === "MANUAL" ? (
                <FormGroup row style={{ marginTop: 10 }}>
                  <Col>
                    <InputText
                      id="manualWidth"
                      label={labels["width"]}
                      value={this.state.details.manualWidth}
                      onChange={this.handleChange}
                      disabled={disabled}
                    ></InputText>
                  </Col>
                  <Col>
                    <InputText
                      id="manualHeight"
                      label={labels["height"]}
                      value={this.state.details.manualHeight}
                      onChange={this.handleChange}
                      disabled={disabled}
                    ></InputText>
                  </Col>
                </FormGroup>
              ) : null}
            </Col>

            <Col md={{ size: 2 }}>
              <Radio
                id="installationType"
                label={labels["installationType"]}
                options={labels["installationTypeOptions"]}
                value={this.state.details.installationType}
                onChange={this.handleChange}
                disabled={disabled}
              ></Radio>
            </Col>
          </FormGroup>
          <FormGroup style={{ width: "40%" }}>
            <Label>{labels["notes"]}</Label>
            <Input
              id="notes"
              type="textarea"
              value={this.state.details.notes}
              rows={5}
              onChange={this.handleChange}
            />
          </FormGroup>
        </Form>
        <FilesSection
          filesData={this.state.details.filesData}
          handleFiles={this.handleFiles}
        />
      </div>
    );
  }
}

export default Stove;
