import React, { Component, useState } from "react";
import {
  Form,
  FormGroup,
  FormText,
  Container,
  Row,
  Col,
  Button,
  CustomInput,
  Input,
  Label,
  Tooltip
} from "reactstrap";

import IslandCard from "./Cards/island"
import CoverCard from "./Cards/cover"

import AddNoteDialog from "./addNoteDialog";

import { forwardRef } from 'react';

class Item extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.getData = this.getData.bind(this);
    
    this.switchItemType = this.switchItemType.bind(this);
    this.setTooltipOpen = this.setTooltipOpen.bind(this)
    
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.save = this.save.bind(this);
    
    this.labels = Object.assign({}, require("./labels.json"));

    this.islandCardDetails = {
        numberOfSeats: undefined,
        exitInCM: 0,
        electricOutlet: "YES"
    }
    this.coverCardDetails = {
      height: "WINDOW"
    }

    this.state = {
      data :{
        item_id: '',
        item_type_id: undefined,
        company_id: undefined,
        details: '',
        panel: '',
        panelSize: '',
        length: '',
        niche: undefined,
        price : '',
        notes: '',
        card: undefined,
        cardDetails: undefined
      },
      showDialog: false,
      tooltipOpen: false
    }

    this.allCompanies = [];
    this.allItems     = [];
    this.allItemTypes = [];
    // this.noteRef = React.createRef();

  }
  setTooltipOpen = () => {
    this.setState({ tooltipOpen: !this.state.tooltipOpen });
  }

  openDialog = () => {
    this.setState({ showDialog: true });
  }

  closeDialog = () => {
    this.setState({ showDialog: false });
  };

  save = (noteData) => {
    console.log('save note', noteData)
    var id = "note";
    var eventValue = noteData.note;
    var index = this.props.index;

    console.log('item id', id)
    console.log('item eventValue', eventValue)
    console.log('item index', index)

    let eventObj = {
      target: {
        value: eventValue,
        id: id,
        index: index
      }
    };

    this.setState(prevState => {
      let data = Object.assign({}, prevState.data);
      data["notes"] = noteData.note;
      eventObj.target.value = data;

      return { data };
    }, () => {
      this.props.onChange(eventObj);
    });
  };

  getData(){
    return this.state.data;
  }

  setCardData(id, value){
    this.setState(prevState => {
      let data = Object.assign({}, prevState.data);
      if (id && value) {
      data.cardDetails[id] = value; 
      }
      return {data}
    });
  }

  switchItemType(itemTypeId, data) {
    console.log('item type changed')
    var allItemTypes = this.props.allItemTypes;

    let value = allItemTypes.filter(item => item.id === parseInt(itemTypeId))[0];
    console.log('item type value', value)

    if (value) {
      data.item_id = undefined
      data.company_id = undefined
      data.details = undefined
      data.item_type_id = parseInt(itemTypeId)

      switch (value.id) {
        case 2:
          // data.card = new IslandCard();
          data.cardDetails = Object.assign({}, this.islandCardDetails);
          break;

        case 3:
          // data.card = new CoverCard();
          data.cardDetails = Object.assign({}, this.coverCardDetails);
          break;

        default: 
        // data.card = undefined; 
        data.cardDetails = undefined
      }

    } else {
      data.item_id      = undefined
      data.item_type_id = undefined
      data.company_id   = undefined
      data.details      = undefined
      // data.card         = undefined
      data.cardDetails  = undefined
    }

    return data;
  }

  loadData(dataToLoad, allItems, allCompanies, allItemTypes){
    this.allCompanies = allCompanies;
    this.allItems     = allItems;
    this.allItemTypes = allItemTypes;
    
    console.log('item load data', dataToLoad)

    this.setState(prevState => {
      let data = Object.assign({}, prevState.data);
      data = dataToLoad;
      
      return {data};
    });
    // this.noteRef.current.loadNote(dataToLoad.notes);
  }

  handleChange(event) {
    var value;
    var id = event.target.id;
    var eventValue = event.target.value
    var index = this.props.index;
    console.log('item id', id)
    console.log('item eventValue', eventValue)
    console.log('item index', index)

    let eventObj = {
      target: {
        value: undefined,
        id: id,
        index: index
      }
    };

    var allItems = this.props.allItems;

    this.setState(prevState => {
      let data = Object.assign({}, prevState.data);

      switch (id) {
        case "name":
          console.log('name eventValue', eventValue)
          value = allItems.filter(item => item.id === parseInt(eventValue))[0];
          console.log('name value after filter', value)
          if (value) {
            data = this.switchItemType(value.item_type_id, data)
            
            data.company_id = value.company_id
            data.item_id = value.id
            data.details = value.details

          } else {
            data.item_id = undefined
            data.company_id = undefined
            data.details = undefined
          }
          break;

        case "item_type_id":
          data = this.switchItemType(eventValue, data)
          break;
        default:
          data[id] = eventValue;
      }

      eventObj.target.value = data;
      return { data };
    }, () => {
      this.props.onChange(eventObj);
    });

  }
  componentDidMount() {
    console.log("item componentDidMount")
    console.log("state", this.state);
    console.log("props", this.props);
  }

  removeItem(event) {
    this.props.removeItem(event.target.id);
  }
  render() {
    const disabled      = false;
    const labels        = this.labels;
    const index         = this.props.index;
    const item_type_id  = this.state.data.item_type_id;
    const company_id    = this.state.data.company_id;
    const item_id       = this.state.data.item_id;
    const details       = this.state.data.details;
    const panel         = this.state.data.panel;
    const panelSize       = this.state.data.panelSize;
    const length        = this.state.data.length;
    const niche         = this.state.data.niche;
    const price         = this.state.data.price;
    const notes         = this.state.data.notes;
    const total         = length !== undefined && length !== 0 ?  
    Math.round(((length * price) + Number.EPSILON) * 100) / 100 : 
    Math.round(((price) + Number.EPSILON) * 100) / 100 ;

    const allItemTypes  = this.props.allItemTypes;
    const allItems      = this.props.allItems;
    const allCompanies  = this.props.allCompanies;
    const itemsHashSet  = this.props.itemsHashSet;

    const allRelevantCompanies = allCompanies.filter(company => (itemsHashSet && itemsHashSet[item_type_id] && itemsHashSet[item_type_id][company.id]) )

    const allRelevantItems = itemsHashSet && itemsHashSet[item_type_id] && itemsHashSet[item_type_id][company_id] ? itemsHashSet[item_type_id][company_id] : []
    

    let nameOptionsElements = allRelevantItems.map((option, index) => {
      return (
        <option key={index} value={option.id}>
          {option.display_name}
        </option>
      );
    });

    let companiesOptionsElements = allCompanies.map((option, index) => {
      return (
        <option key={index} value={option.id}>
          {option.display_name}
        </option>
      );
    });

    let itemTypesOptionsElements = allItemTypes.map((option, index) => {
      return (
        <option key={index} value={option.id}>
          {option.display_name}
        </option>
      );
    });

    let nicheOptionsElements = labels.nicheOptions.map((option, index) => {
      return (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      );
    });

    return (
      <tr>
        <td>
          <Label>{index+1}</Label>
        </td>
        <td>
          <CustomInput
            index={index}
            type="select"
            id="item_type_id"
            name="item_type_idSelect"
            onChange={this.handleChange}
            value={item_type_id}
          >
            <option value={undefined}>{labels.choose}</option>
            {itemTypesOptionsElements}
          </CustomInput>
        </td>

        <td>
          <CustomInput
            index={index}
            type="select"
            id="company_id"
            name="company_idSelect"
            onChange={this.handleChange}
            value={company_id}
            disabled={item_type_id === undefined}
          >
            <option value={undefined}>{labels.choose}</option>
            {companiesOptionsElements}
          </CustomInput>
        </td>

        
        <td>
          {/* <CustomInput
            index={index}
            type="select"
            id="name"
            name="nameSelect"
            onChange={this.handleChange}
            value={item_id}
            disabled={company_id === undefined}
          >
            <option value={undefined}>{labels.choose}</option>
            {nameOptionsElements}
          </CustomInput> */}
          <Input
            index={index}
            type="item_id"
            value={item_id}
            id="item_id"
            name="item_id"
            onChange={this.handleChange}
          />
        </td>
        

        <td>
          {/* <Label>{details}</Label> */}
          <Input
            index={index}
            type="details"
            value={details}
            id="details"
            name="details"
            onChange={this.handleChange}
          />
        </td>

        <td>
          <Input
            index={index}
            type="text"
            value={panel}
            id="panel"
            name="panel"
            onChange={this.handleChange}
          />
        </td>
        <td>
          <Input
            index={index}
            type="text"
            value={panelSize}
            id="panelSize"
            name="panelSize"
            onChange={this.handleChange}
          />
        </td>

        <td>
        <CustomInput
            index={index}
            type="select"
            id="niche"
            name="nicheSelect"
            onChange={this.handleChange}
            value={niche}
          >
            <option value={undefined}>{labels.choose}</option>
            {nicheOptionsElements}
          </CustomInput>
        </td>
        <td>
          <Input
            index={index}
            type="text"
            value={length}
            id="length"
            name="length"
            onChange={this.handleChange}
          />
        </td>
        {/* <td>
          <Input
            index={index}
            type="text"
            value={quantity}
            id="quantity"
            name="quantity"
            onChange={this.handleChange}
          />
        </td> */}
        <td>
          <Input
            index={index}
            type="text"
            value={price}
            id="price"
            name="price"
            onChange={this.handleChange}
          />
        </td>
        <td>
          {/* <Input
            index={index}
            type="text"
            value={notes}
            id="notes"
            name="notes"
            onChange={this.handleChange}
          /> */}<span>
          <Button
            id={"noteBtn"+index}
            onClick={this.openDialog}
            style={{ height: "50%", margin: "0" }}
          >
            עריכה
          </Button>
          <Tooltip
        placement="left"
        isOpen={this.state.tooltipOpen}
        target={"noteBtn"+index}
        toggle={this.setTooltipOpen}
      >
       {notes}
      </Tooltip>
      </span>
          {this.state.showDialog ? (
          <AddNoteDialog
            index={index}
            showDialog={this.state.showDialog}
            closeDialog={this.closeDialog}
            save={this.save}
            note={notes}
          ></AddNoteDialog>
        ) : null}
        </td>
        <td>
          <Label>{''+total}</Label>
        </td>
        <td>
          <Button
            id={index}
            onClick={this.removeItem}
            style={{ height: "50%", margin: "0px 15px 0 0" }}
          >
            {labels.removeItem}
          </Button>
        </td>
      </tr>
      
    );
  }
}

export default Item;
