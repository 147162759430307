import React, { Component } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Label,
  CustomInput
} from "reactstrap";


import { PDFViewer, BlobProvider} from "@react-pdf/renderer";

import SurveyorPDF from "../SurveyorPDF/pdf";

import api from "../../Services/api";
// import aes from 'crypto-js/aes';
// import encUtf8 from 'crypto-js/enc-utf8';

class SurveyorForm extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSurveyor = this.handleChangeSurveyor.bind(this);
  
    this.print = this.print.bind(this);
    this.renderPDF = this.renderPDF.bind(this);
    this.downloadPDF = this.downloadPDF.bind(this);
    this.send = this.send.bind(this);

    this.labels = Object.assign({}, require("./labels.json"));

    this.state = {
      orderId: undefined,
      orderDetails: undefined,
      companyDetails: {
        name: this.labels.companyDetails.name,
        address: this.labels.companyDetails.address,
        phone: this.labels.companyDetails.phone,
        fax: this.labels.companyDetails.fax,
        email: this.labels.companyDetails.email
      },
      disableSendButton: false,
      chosenSurveyor : undefined

    };

    this.allItems = [];
    this.allItemTypes = [];
    this.allCompanies = [];
    this.totalPrice = 0;

  }

  

  calculateTotalPrice(items) {
    for (var i =0; i< items.length; i++) {
      this.totalPrice +=  parseFloat(items[i].price);

    }

  }
  componentDidMount() {
    let pathname = window.location.pathname.split("/surveyorForm/");
    if (pathname.length == 2) {
      this.setState({ orderId: pathname[1] });

      let ordersCall = api.orders.get({
        id: pathname[1]
      });

      let itemsCall = api.items.get({});
      let itemTypesCall = api.itemTypes.get({});
      let companiesCall = api.companies.get({});
      let surveyorsCall = api.surveyors.get({});
    
      
      Promise.all([ordersCall, itemsCall, itemTypesCall, companiesCall, surveyorsCall]).then(values => {

        this.allItems = values[1].data.items;
        this.allItemTypes = values[2].data.item_types;
        this.allCompanies = values[3].data.companies;
        this.allSurveyors = values[4].data.surveyors;

        let data = values[0].data.orders[0].data;
        let seller_id = values[0].data.orders[0].seller_id;
        let surveyor_id = values[0].surveyor_id;
        let status = values[0].data.orders[0].status;
        this.loadData(data, seller_id, surveyor_id, status);
        this.calculateTotalPrice(data.Items)

      });
    }
  }

  loadData(dataToLoad, sellerId, surveyorId, status) {
    let orderDetailsObject = {
      data: dataToLoad,
      sellerId: sellerId,
      surveyorId: surveyorId,
      status: status
    };
    this.setState({ orderDetails: orderDetailsObject });
  }

  print = () => {
    this.setState({ formCanvasDataUrl: true });
  };
  downloadPDF = csvURL => {
    let tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "Order" + Date.now().toString() + ".pdf");
    tempLink.click();
    tempLink.remove();
    this.setState({ formCanvasDataUrl: null });
  };

  handleChange(event) {
    let value = event.target.value;
    let id = event.target.id;

  }

  handleChangeSurveyor(event) {
    let value = event.target.value;
    let id = event.target.id;

    this.setState({ chosenSurveyor: value });

  }

  renderPDF() {
    return (
      
      this.state.orderDetails &&
        this.state.orderDetails.data 
    );
  }

  send(pdfBase64data, chosenSurveyor) {
    let data = { pdf: pdfBase64data };
    let pathname = window.location.pathname.split("/surveyorForm/");

    if (pathname.length == 2) {
      data["id"] = pathname[1];
      data["messageType"] = "SURVEYOR";
      data["destination"] = chosenSurveyor;

      api.orders
        .sendPDF(data)
        .then(response => {
          this.setState({ disableSendButton: true });

        })
        .catch(error => {
          this.setState({ disableSendButton: false });
        })
        .finally(() => {

          setTimeout(() => {
          }, 2000);
          // always executed
        });
    }
  }

  render() {
    const disabled = false;
    const labels = this.labels;
    var pdfDataToSend = undefined;
    var disableSendButton = this.state.disableSendButton;
    const allSurveyors = this.allSurveyors;
    var chosenSurveyor = this.state.chosenSurveyor;

    if (allSurveyors != undefined) {
      var surveyorsOptionsElements = allSurveyors.map((option, index) => {
        if (option.email != null){
          return (
            <option key={index} value={option.email}>
              {option.name}
            </option>
          );
        }
      });
    } else {
      var surveyorsOptionsElements = 
          <option value={undefined}>
            {labels.choose}
          </option>
       
    }
   

    return (
      <div
        style={{
          width: "95%",
          marginLeft: "auto",
          marginRight: "auto",
          direction: "rtl",
          textAlign: "right"
        }}
      >
        <Form style={{ marginTop: "10px"}}>
          {/* <FormGroup row> */}
          <div style={{
         
            textAlign: "center", 
          }}>

          <Label style={{
            fontSize:20}}>עמוד מודד</Label>
          </div>
          {this.renderPDF() ? (
            // <div id="def">
            <PDFViewer
              width={"100%"}
              height={"600"}
              children={
                <SurveyorPDF
                  companyDetails={this.state.companyDetails}
                  order={this.state.orderId}
                  notes={this.state.orderDetails.data.Notes}
                  sinkDetails={this.state.orderDetails.data.SinkDetails}
                  carpentryDetails={this.state.orderDetails.data.CarpentryDetails}
                  stoveDetails={this.state.orderDetails.data.Stove}
                  technicalQuestions={
                    this.state.orderDetails.data.TechnicalQuestions
                  }
                  customerDetails={this.state.orderDetails.data.CustomerDetails}
                  drills={this.state.orderDetails.data.DrillingDetails}
                  items={this.state.orderDetails.data.Items}
                  totalPrice={this.totalPrice}
                  allItems={this.allItems}
                  allCompanies={this.allCompanies}
                  allItemTypes={this.allItemTypes}
                />
              }
            ></PDFViewer>
          ) : 
          null}

          {this.renderPDF() ? (
            <BlobProvider
              document={
                <SurveyorPDF
                companyDetails={this.state.companyDetails}
                order={this.state.orderId}
                notes={this.state.orderDetails.data.Notes}
                sinkDetails={this.state.orderDetails.data.SinkDetails}
                carpentryDetails={this.state.orderDetails.data.CarpentryDetails}
                stoveDetails={this.state.orderDetails.data.Stove}
                technicalQuestions={
                  this.state.orderDetails.data.TechnicalQuestions
                }
                customerDetails={this.state.orderDetails.data.CustomerDetails}
                drills={this.state.orderDetails.data.DrillingDetails}
                items={this.state.orderDetails.data.Items}
                totalPrice={this.totalPrice}
                allItems={this.allItems}
                allCompanies={this.allCompanies}
                allItemTypes={this.allItemTypes}
                />
              }
            >
              {({ blob, url, loading, error }) => {
                // Do whatever you need with blob here
                if (blob != null) {
                  var reader = new FileReader();
                  reader.readAsDataURL(blob);
                  reader.onloadend = () => {
                    var base64data = reader.result;
                    pdfDataToSend = base64data;
                  };
                  // this.sendPDF(blob);
                }
                return <div></div>;
              }}
            </BlobProvider>
          ) : null}
        </Form>

        <div
          style={{
            textAlign: "center"
          }}
        >
        <Row>
          <Col md={{ size: 2 }}>
          <CustomInput
            type="select"
            id="surveyor"
            name="surveyorSelect"
            onChange={this.handleChangeSurveyor}
            disabled={disableSendButton}
            value={chosenSurveyor}
          >
            <option value={undefined}>{labels.choose}</option>
            {surveyorsOptionsElements}
          </CustomInput>
          </Col>

          <Col md={{ size: 2 }}> 
          <Button disabled={disableSendButton} color="success" onClick={() => this.send(pdfDataToSend, chosenSurveyor)}>
          {disableSendButton ? <Label > {labels.sent} </Label> : <Label >{labels.send} </Label>}
          </Button>
          </Col>
        </Row>
         
          
        </div>
      </div>
    );
  }
}

export default SurveyorForm;
