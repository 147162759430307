import React, { Component } from "react";
import {
  Col,
  CustomInput,
  Form,
  FormGroup,
  Label
} from "reactstrap";

import validator from "validator";
import InputDate from "../InputDate/inputDate";
import InputEmail from "../InputEmail/inputEmail";
import InputNumber from "../InputNumber/inputNumber";
import InputText from "../InputText/inputText";
import Radio from "../Radio/radio";

class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    this.handleChangeDate = this.handleChangeDate.bind(this);

    this.loadData = this.loadData.bind(this);

    this.labels = Object.assign({}, require("./labels.json"));
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    this.isTouched = {
      date: false,
      firstName: false,
      lastName: false,
      address: false,
      houseNumber: false,
      entrance: false,
      floor: false,
      apartmentNumber: false,
      code: false,
      city: false,
      email: false,
      contactForSurvey: false,
      contactForInstall: false,
      phoneNumber1: false,
      phoneNumber2: false,
      installationType: false,
      houseType: false,
      parkingType: false,
      keyType: false,
      elevatorType: false,
      cargoElevatorLocation: false,
      crane: false,
      craneAccess: false,
      balconySafetyRail: false,
      measureStairsRotation: false,
    };

    this.state = {
      customerDetails: {
        date: `${year}-${month < 10 ? 0 : ""}${month}-${
          day < 10 ? 0 : ""
        }${day}`,
        firstName: "",
        lastName: "",
        address: "",
        houseNumber: "",
        entrance: "",
        floor: "",
        apartmentNumber: "",
        code: "",
        city: "",
        email: "",
        contactForSurvey: "",
        contactForInstall: "",
        referer: undefined,
        otherReferer: undefined,
        phoneNumber1: "",
        phoneNumber2: "",
        installationType: undefined,
        houseType: undefined,
        parkingType: undefined,
        keyType: undefined,
        elevatorType: undefined,
        cargoElevatorLocation: undefined,
        crane: undefined,
        craneAccess: undefined,
        balconySafetyRail: undefined,
        measureStairsRotation: undefined,
      },
    };

    this.customerDetailsKeys = Object.keys(this.state.customerDetails);
  }

  componentDidMount() {}
  componentDidUpdate() {}

  loadData(data) {
    this.setState((prevState) => {
      let customerDetails = Object.assign({}, prevState.customerDetails);
      customerDetails = data;
      this.props.onChange("customerDetails", customerDetails);
      return { customerDetails };
    });
  }

  handleChangeDate(event) {
    let value = event.target.value;
    let id = event.target.id;

    this.setState((prevState) => {
      let customerDetails = Object.assign({}, prevState.customerDetails);
      customerDetails["date"] = value;
      this.props.onChange("customerDetails", customerDetails);
      return { customerDetails };
    });
  }

  handleChange(event) {
    let value = event.target.value;
    let id = event.target.id;
    this.isTouched[id] = true;

    this.setState((prevState) => {
      let customerDetails = Object.assign({}, prevState.customerDetails);

      switch (id) {
        case "installationType":
          if (value === "DELIVERY" || value === "PICKUP") {
            customerDetails["houseType"] = undefined;
            customerDetails["elevatorType"] = undefined;
            customerDetails["cargoElevatorLocation"] = undefined;
          }
        case "houseType":
          if (value === "PRIVATE") {
            customerDetails["elevatorType"] = undefined;
            customerDetails["cargoElevatorLocation"] = undefined;
          }
        case "elevatorType":
          if (value !== "CARGO") {
            customerDetails["cargoElevatorLocation"] = undefined;
          }
        case "craneAccess":
          if (value !== "YES") {
            customerDetails["balconySafetyRail"] = undefined;
          }
        default:
          customerDetails[id] = value;
      }

      this.props.onChange("customerDetails", customerDetails);
      return { customerDetails };
    });
  }

  render() {
    const disabled = false;
    const labels = this.labels;

    const customerDetails = this.state.customerDetails;
    const firstName = customerDetails.firstName;
    const lastName = customerDetails.lastName;
    const contactForSurvey = customerDetails.contactForSurvey;
    const contactForInstall = customerDetails.contactForInstall;
    const date = customerDetails.date;
    const address = customerDetails.address;
    const houseNumber = customerDetails.houseNumber;
    const otherReferer = customerDetails.otherReferer;

    let isValid = {};
    let isInvalid = {};

    for (var i = 0; i < this.customerDetailsKeys.length; i++) {
      let key = this.customerDetailsKeys[i];

      switch (key) {
        case "email":
          isValid[key] =
            customerDetails[key] !== "" &&
            customerDetails[key] !== undefined &&
            validator.isEmail(customerDetails[key]);
          isInvalid[key] =
            this.isTouched[key] === true &&
            (customerDetails[key] === "" ||
              !validator.isEmail(customerDetails[key]));
          break;
        case "phoneNumber1":
        case "phoneNumber2":
          isValid[key] =
            customerDetails[key] !== "" &&
            customerDetails[key] !== undefined &&
            validator.isMobilePhone(customerDetails[key]);
          isInvalid[key] =
            this.isTouched[key] === true &&
            (customerDetails[key] === "" ||
              !validator.isMobilePhone(customerDetails[key]));
          break;
        default:
          isValid[key] =
            customerDetails[key] !== "" && customerDetails[key] !== undefined;
          isInvalid[key] =
            this.isTouched[key] === true && customerDetails[key] === "";
      }
    }

    // const isDateValid = (this.state.date !== "" && this.state.date !== undefined);
    // const isDateInvalid = (this.isTouched['date'] === true && this.state.date === "");

    let installationTypeOptionsElements = labels["installationTypeOptions"].map(
      (option, index) => {
        return (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        );
      },
    );

    let houseTypeOptionsElements = labels["houseTypeOptions"].map(
      (option, index) => {
        return (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        );
      },
    );
    let refererOptionsElements = labels["refererOptions"].map(
      (option, index) => {
        return (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        );
      },
    );

    return (
      <div>
        <Form style={{ marginTop: "20px" }}>
            <FormGroup row>
              <Col md={{ size: 2 }}>
                <InputText
                  id="firstName"
                  label={labels["firstName"]}
                  value={firstName}
                  onChange={this.handleChange}
                  disabled={disabled}
                  valid={isValid["firstName"]}
                  invalid={isInvalid["firstName"]}
                ></InputText>
              </Col>
              <Col md={{ size: 2 }}>
                <InputText
                  id="lastName"
                  label={labels["lastName"]}
                  value={lastName}
                  onChange={this.handleChange}
                  disabled={disabled}
                  valid={isValid["lastName"]}
                  invalid={isInvalid["lastName"]}
                ></InputText>
              </Col>
              <Col
                md={
                  this.state.customerDetails.referer != "OTHER"
                    ? { size: 2, offset: 4 }
                    : { size: 2 }
                }
              >
                <Label>{labels["referer"]}</Label>
                <CustomInput
                  type="select"
                  id="referer"
                  name="refererSelect"
                  onChange={this.handleChange}
                  value={this.state.customerDetails.referer}
                >
                  <option value={undefined}>{labels.choose}</option>
                  {refererOptionsElements}
                </CustomInput>
              </Col>
              {this.state.customerDetails.referer == "OTHER" ? (
                <Col md={{ size: 2, offset: 2 }}>
                  <InputText
                    id="otherReferer"
                    label={labels["referer"]}
                    value={otherReferer}
                    onChange={this.handleChange}
                    disabled={disabled}
                  ></InputText>
                </Col>
              ) : null}

              <Col md={{ size: 2 }}>
                <InputDate
                  id="date"
                  label={labels["date"]}
                  value={date}
                  onChange={this.handleChangeDate}
                  disabled={disabled}
                  valid={isValid["date"]}
                  invalid={isInvalid["date"]}
                ></InputDate>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={{ size: 2 }}>
                <InputText
                  id="contactForSurvey"
                  label={labels["contactForSurvey"]}
                  value={contactForSurvey}
                  onChange={this.handleChange}
                  disabled={disabled}
                  valid={isValid["contactForSurvey"]}
                  invalid={isInvalid["contactForSurvey"]}
                ></InputText>
              </Col>
              <Col md={{ size: 2 }}>
                <InputText
                  id="contactForInstall"
                  label={labels["contactForInstall"]}
                  value={contactForInstall}
                  onChange={this.handleChange}
                  disabled={disabled}
                  valid={isValid["contactForInstall"]}
                  invalid={isInvalid["contactForInstall"]}
                ></InputText>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md={{ size: 2 }}>
                <InputText
                  id="phoneNumber1"
                  label={labels["phoneNumber1"]}
                  value={this.state.customerDetails.phoneNumber1}
                  onChange={this.handleChange}
                  disabled={disabled}
                  valid={isValid["phoneNumber1"]}
                  invalid={isInvalid["phoneNumber1"]}
                ></InputText>
              </Col>

              <Col md={{ size: 2 }}>
                <InputText
                  id="phoneNumber2"
                  label={labels["phoneNumber2"]}
                  value={this.state.customerDetails.phoneNumber2}
                  onChange={this.handleChange}
                  disabled={disabled}
                  valid={isValid["phoneNumber2"]}
                  invalid={isInvalid["phoneNumber2"]}
                ></InputText>
              </Col>

              <Col md={{ size: 3 }}>
                <InputEmail
                  id="email"
                  label={labels["email"]}
                  value={this.state.customerDetails.email}
                  onChange={this.handleChange}
                  disabled={disabled}
                  valid={isValid["email"]}
                  invalid={isInvalid["email"]}
                ></InputEmail>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md={{ size: 2 }}>
                <InputText
                  id="address"
                  label={labels["address"]}
                  value={address}
                  onChange={this.handleChange}
                  disabled={disabled}
                  valid={isValid["address"]}
                  invalid={isInvalid["address"]}
                ></InputText>
              </Col>
              <Col md={{ size: 2 }}>
                <InputNumber
                  id="houseNumber"
                  label={labels["houseNumber"]}
                  value={houseNumber}
                  onChange={this.handleChange}
                  disabled={disabled}
                  valid={isValid["houseNumber"]}
                  invalid={isInvalid["houseNumber"]}
                ></InputNumber>
              </Col>

              <Col md={{ size: 2 }}>
                <InputText
                  id="city"
                  label={labels["city"]}
                  value={this.state.customerDetails.city}
                  onChange={this.handleChange}
                  disabled={disabled}
                  valid={isValid["city"]}
                  invalid={isInvalid["city"]}
                ></InputText>
              </Col>

              <Col md={{ size: 2 }}>
                <Label>{labels["installationType"]}</Label>
                <CustomInput
                  type="select"
                  id="installationType"
                  name="installationTypeSelect"
                  onChange={this.handleChange}
                  value={this.state.customerDetails.installationType}
                >
                  <option value={undefined}>{labels.choose}</option>
                  {installationTypeOptionsElements}
                </CustomInput>
              </Col>

              {this.state.customerDetails.installationType === "FULL" ||
              this.state.customerDetails.installationType ===
                "SURFACE_DECOMPOSITION" ||
              this.state.customerDetails.installationType ===
                "SURFACE_AND_CERAMIC_DECOMPOSITION" ? (
                <Col md={{ size: 2 }}>
                  <Label>{labels["houseType"]}</Label>
                  <CustomInput
                    type="select"
                    id="houseType"
                    name="houseTypeSelect"
                    onChange={this.handleChange}
                    value={this.state.customerDetails.houseType}
                  >
                    <option value={undefined}>{labels.choose}</option>
                    {houseTypeOptionsElements}
                  </CustomInput>
                </Col>
              ) : null}
            </FormGroup>

            <FormGroup row>
              <Col md={{ size: 1 }}>
                {this.state.customerDetails.houseType === "BUILDING" ? (
                  <InputText
                    id="entrance"
                    label={labels["entrance"]}
                    value={this.state.customerDetails.entrance}
                    onChange={this.handleChange}
                    disabled={disabled}
                  ></InputText>
                ) : null}
              </Col>

              <Col md={{ size: 1 }}>
                {this.state.customerDetails.houseType === "BUILDING" ? (
                  <InputText
                    id="floor"
                    label={labels["floor"]}
                    value={this.state.customerDetails.floor}
                    onChange={this.handleChange}
                    disabled={disabled}
                  ></InputText>
                ) : null}
              </Col>

              <Col md={{ size: 1 }}>
                {this.state.customerDetails.houseType === "BUILDING" ? (
                  <InputText
                    id="apartmentNumber"
                    label={labels["apartmentNumber"]}
                    value={this.state.customerDetails.apartmentNumber}
                    onChange={this.handleChange}
                    disabled={disabled}
                  ></InputText>
                ) : null}
              </Col>

              <Col md={{ size: 1 }}>
                {this.state.customerDetails.houseType === "BUILDING" ? (
                  <InputText
                    id="code"
                    label={labels["code"]}
                    value={this.state.customerDetails.code}
                    onChange={this.handleChange}
                    disabled={disabled}
                    style={{ width: "70px" }}
                  ></InputText>
                ) : null}
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md={{ size: 1 }}>
                {this.state.customerDetails.houseType === "BUILDING" ? (
                  <Radio
                    id="elevatorType"
                    label={labels["elevatorType"]}
                    options={labels["elevatorTypeOptions"]}
                    value={this.state.customerDetails.elevatorType}
                    onChange={this.handleChange}
                    disabled={disabled}
                  ></Radio>
                ) : null}
              </Col>
              <Col md={{ size: 1 }}>
                {this.state.customerDetails.elevatorType === "CARGO" ? (
                  <Radio
                    id="cargoElevatorLocation"
                    label={labels["cargoElevatorLocation"]}
                    options={labels["cargoElevatorLocationOptions"]}
                    value={this.state.customerDetails.cargoElevatorLocation}
                    onChange={this.handleChange}
                    disabled={disabled}
                  ></Radio>
                ) : null}
              </Col>

              <Col md={{ size: 1 }}>
                {this.state.customerDetails.houseType === "BUILDING" ? (
                  <Radio
                    id="crane"
                    label={labels["crane"]}
                    options={labels["craneOptions"]}
                    value={this.state.customerDetails.crane}
                    onChange={this.handleChange}
                    disabled={disabled}
                  ></Radio>
                ) : null}
              </Col>

              <Col md={{ size: 1 }}>
                {this.state.customerDetails.houseType === "BUILDING" ? (
                  <Radio
                    id="craneAccess"
                    label={labels["craneAccess"]}
                    options={labels["craneAccessOptions"]}
                    value={this.state.customerDetails.craneAccess}
                    onChange={this.handleChange}
                    disabled={disabled}
                  ></Radio>
                ) : null}
              </Col>

              <Col md={{ size: 2 }}>
                {this.state.customerDetails.craneAccess === "YES" ? (
                  <Radio
                    id="balconySafetyRail"
                    label={labels["balconySafetyRail"]}
                    options={labels["balconySafetyRailOptions"]}
                    value={this.state.customerDetails.balconySafetyRail}
                    onChange={this.handleChange}
                    disabled={disabled}
                  ></Radio>
                ) : null}
              </Col>

              <Col md={{ size: 2 }}>
                {this.state.customerDetails.houseType === "BUILDING" ? (
                  <Radio
                    id="measureStairsRotation"
                    label={labels["measureStairsRotation"]}
                    options={labels["measureStairsRotationOptions"]}
                    value={this.state.customerDetails.measureStairsRotation}
                    onChange={this.handleChange}
                    disabled={disabled}
                  ></Radio>
                ) : null}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={{ size: 2 }}>
                <Radio
                  id="parkingType"
                  label={labels["parkingType"]}
                  options={labels["parkingTypeOptions"]}
                  value={this.state.customerDetails.parkingType}
                  onChange={this.handleChange}
                  disabled={disabled}
                ></Radio>
              </Col>
              <Col md={{ size: 2 }}>
                <Radio
                  id="keyType"
                  label={labels["keyType"]}
                  options={labels["keyTypeOptions"]}
                  value={this.state.customerDetails.keyType}
                  onChange={this.handleChange}
                  disabled={disabled}
                ></Radio>
              </Col>
            </FormGroup>
        </Form>
      </div>
    );
  }
}

export default CustomerDetails;
