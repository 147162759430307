import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col
} from "reactstrap";
import InputText from "../InputText/inputText";
import InputCheckbox from "../InputCheckbox/inputCheckbox";


class AddItem extends Component {
  constructor(props) {
    super(props);
    this.closeDialog = this.closeDialog.bind(this);
    this.save = this.save.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.labels = Object.assign({}, require("./labels.json"));

    this.state = {
      details: {
        name: "",
        display_name: "",
        show_on_surveyor_pdf: true,
      }
    };
  }

  closeDialog = () => {
    this.props.closeDialog();
  };

  save = () => {
    let key = this.props.dialogType;
    let name = this.state.details.name;
    let display_name = this.state.details.display_name;
    let show_on_surveyor_pdf = this.state.details.show_on_surveyor_pdf;

    this.props.save({ 
        key: key, 
        name: name, 
        display_name: display_name ,
        show_on_surveyor_pdf: show_on_surveyor_pdf
    });
  };

  handleChange = event => {
    let id = event.target.id;
    let value = event.target.value;
    if (id == 'show_on_surveyor_pdf') {
      
      value = event.target.checked;
    }

    this.setState(prevState => {
      let details = Object.assign({}, prevState.details);
      details[id] = value;
      return { details };
    });
  };

  render() {
    const name = this.state.details.name;
    const display_name = this.state.details.displayName;
    const show_on_surveyor_pdf = this.state.details.show_on_surveyor_pdf;
    const labels = this.labels;
    const disabled = false;

    return (
      <div>
        <Modal
          isOpen={this.props.showDialog}
          style={{ direction: "rtl", width: "400px", textAlign: "right" }}
        >
          <ModalHeader>
            {labels["add"]} {labels[this.props.dialogType]}
          </ModalHeader>
          <ModalBody>
            <Col>
              <InputText
                id="name"
                label={labels["name"]}
                value={name}
                onChange={this.handleChange}
                disabled={disabled}
              ></InputText>
            </Col>
            <Col>
              <InputText
                id="display_name"
                label={labels["display_name"]}
                value={display_name}
                onChange={this.handleChange}
                disabled={disabled}
              ></InputText>
            </Col>
            {this.props.dialogType == "itemType" ? (
              <Col style={{marginTop: '5px'}}>
                <InputCheckbox
                  id="show_on_surveyor_pdf"
                  label={labels["show_on_surveyor_pdf"]}
                  checked={show_on_surveyor_pdf}
                  onChange={this.handleChange}
                  disabled={disabled}>
                </InputCheckbox>
              </Col>
            ) : null}
            
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.save}>
              שמירה
            </Button>
            <Button color="secondary" onClick={this.closeDialog}>
              ביטול
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default AddItem;
