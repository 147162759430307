import React, { Component } from "react";
import {
  FormGroup,
  Col,
  CustomInput,
  Label
} from "reactstrap";


class Drill extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.labels = Object.assign({}, require("./labels.json"));
  }

  handleChange(event) {
    let eventObj = {
      target: {
        value: event.target.value,
        id: event.target.id,
        index: this.props.index
      }
    };


    this.props.onChange(eventObj);
  }

  render() {
    const labels = this.labels;
    const index = this.props.index;

    let typeOptionsElements = labels.typeOptions.map((option, index) => {
        return (
          <option key={index} value={option.value}>{option.label}</option>
        );
      });

      let placeOptionsElements = labels.placeOptions.map((option, index) => {
        return (
          <option key={index} value={option.value}>{option.label}</option>
        );
      });

      let locationOptionsElements = labels.locationOptions.map((option, index) => {
        return (
          <option key={index} value={option.value}>{option.label}</option>
        );
      });


    return (
          <FormGroup row>
          <Col>
          <Label>{labels.type}</Label>
        <CustomInput value={this.props.type} index={index} type="select" id="type" name="typeSelect" onChange={this.handleChange}>
        <option value={undefined}>{labels.choose}</option>
         {typeOptionsElements}
        </CustomInput>
        </Col>

        <Col>
          <Label>{labels.place}</Label>
        <CustomInput value={this.props.place} index={index} type="select" id="place" name="placeSelect" onChange={this.handleChange}>
        <option value={undefined}>{labels.choose}</option>
         {placeOptionsElements}
        </CustomInput>
        </Col>

        <Col>
          <Label>{labels.location}</Label>
        <CustomInput value={this.props.location} index={index} type="select" id="location" name="locationSelect" onChange={this.handleChange}>
        <option value={undefined}>{labels.choose}</option>
         {locationOptionsElements}
        </CustomInput>
        </Col>

          </FormGroup>
    );
  }
}

export default Drill;
