import * as React from "react";
import { useDropzone } from "react-dropzone";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Button, Container, Row } from "reactstrap";

import api from "../../Services/api";
import { SaveContext } from "../Menu/save-context";

const emptyFile = ({ type }) => new File([null], "", { type });

export class AppFile extends File {
  // This extends File so we can have a isomorphic interface for files coming from the server and files uploaded by user
  constructor({
    file = null,
    status = null,
    id = null,
    url = null,
    name = null,
    type = null,
    createdAt = null,
    sellerId = null,
    dirty = false,
    rotate = 0,
    extension = "",
  }) {
    if (file === null) {
      file = emptyFile({ type });
    }
    super([file], name ?? file.name, {
      type: type ?? file.type,
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      path: file.path,
    });
    // status: "FILE_ACTIVE" | "FILE_CHANGED" | "FILE_DELETED"
    this.status = status ?? "FILE_ACTIVE";
    this.emptyFile = file.name === "";
    this.id = id ?? file.id ?? uuidv4();
    this.url = url ?? file.url;
    this.createdAt = createdAt ?? new Date().toISOString();
    this.sellerId = sellerId;
    this.dirty = dirty;
    this.rotate = rotate;
    this.extension = file.extension ?? extension;
  }
  prepareForSend() {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      status: this.status,
      url: this.url,
      createdAt: this.createdAt,
      sellerId: this.sellerId,
      rotate: this.rotate,
      extension: this.extension,
    };
  }
  getUrl() {
    return this.url ?? URL.createObjectURL(this);
  }
}

export function transformFilesDataToAppFils(data) {
  for (const key in data) {
    if (data[key]?.filesData) {
      data[key].filesData.files = data[key].filesData.files.map(
        (f) => new AppFile(f),
      );
    }
  }
}

export function FilesSection({ filesData, handleFiles }) {
  const { clearSaveButtons } = React.useContext(SaveContext);
  const [allFiles, setAllFiles] = React.useState(filesData?.files ?? []);
  const [sellerId, setSellerId] = React.useState(null);

  console.debug(
    "[@@@ FilesSection] allFiles",
    allFiles,
    "filesData",
    filesData?.files,
  );
  React.useEffect(() => {
    setAllFiles(filesData?.files ?? []);
  }, [filesData?.files]);

  React.useEffect(() => {
    if (clearSaveButtons) {
      for (const f of allFiles) {
        f.dirty = false;
      }
    }
  }, [clearSaveButtons]);

  React.useEffect(() => {
    const getSeller = async () => {
      const response = await api.sellers.get({});
      let seller = null;
      try {
        seller = response.data.sellers.filter((seller) => {
          return seller.email == localStorage.getItem("email");
        })[0];
      } catch (error) {}
      setSellerId(seller.id ?? null);
    };

    getSeller();
  }, []);

  React.useEffect(() => {
    handleFiles(allFiles);
  }, [allFiles]);

  return (
    <Container fluid style={{ marginTop: 40, marginBottom: 40 }}>
      <Row>
        <SelectFiles
          allFiles={allFiles}
          setAllFiles={setAllFiles}
          sellerId={sellerId}
          addBefore
        />
      </Row>
      <Row>
        <FilesList allFiles={allFiles} setAllFiles={setAllFiles} />
      </Row>
      {allFiles.filter((f) => f.status !== "FILE_DELETED").length > 0 ? (
        <Row>
          <SelectFiles
            allFiles={allFiles}
            setAllFiles={setAllFiles}
            sellerId={sellerId}
          />
        </Row>
      ) : null}
    </Container>
  );
}

function FilesList({ allFiles, setAllFiles }) {
  return (
    <>
      {allFiles.length > 0 && (
        <ul
          style={{
            display: "grid",
            rowGap: 48,
            width: "100%",
            listStyleType: "none",
            padding: 0,
          }}
        >
          {allFiles
            .filter((f) => f.status !== "FILE_DELETED")
            .map((file) => (
              <React.Fragment key={`${file.id}`}>
                <FileItem
                  file={file}
                  allFiles={allFiles}
                  setAllFiles={setAllFiles}
                />
              </React.Fragment>
            ))}
        </ul>
      )}
    </>
  );
}

function FileItem({ file, allFiles, setAllFiles }) {
  return (
    <li>
      <div
        style={{
          display: "grid",
          gridTemplateRows: "auto 1fr",
          alignItems: "center",
          border: "2px solid #ced4da",
          borderRadius: "16px",
        }}
      >
        <FileDetails
          file={file}
          allFiles={allFiles}
          setAllFiles={setAllFiles}
        />
        <div style={{ padding: 16 }}>
          <FileDisplay
            file={file}
            allFiles={allFiles}
            setAllFiles={setAllFiles}
          />
        </div>
      </div>
    </li>
  );
}

function FileDetails({ file, allFiles, setAllFiles }) {
  const { saveOrder } = React.useContext(SaveContext);

  const removeFile = (e, file) => {
    setAllFiles(
      [...allFiles].map((f) =>
        sameFile(f, file)
          ? new AppFile({ file: f, status: "FILE_DELETED" })
          : f,
      ),
    );
  };
  const renameFile = (file, name) => {
    setAllFiles(
      [...allFiles].map((f) =>
        sameFile(f, file)
          ? new AppFile({
              file: f,
              name: name,
              dirty: true,
              rotate: file.rotate,
            })
          : f,
      ),
    );
  };

  const fileUrl = file.getUrl();
  const href = `${fileUrl}${
    file.type === "application/pdf" && !fileUrl.includes("blob:")
      ? "__download"
      : ""
  }`;
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr auto",
        alignItems: "center",
        width: "fit-content",
        columnGap: 24,
        background: "#ced4da",
        padding: "16px",
        width: "100%",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
      }}
    >
      <FileName file={file} renameFile={renameFile} />
      <div style={{ display: "flex", columnGap: 16 }}>
        {file.dirty ? (
          <Button
            onClick={() => {
              saveOrder();
              file.dirty = false;
              for (const f of allFiles) {
                f.dirty = false;
              }
            }}
            style={{ backgroundColor: "green", width: "fit-content" }}
          >
            שמירה
          </Button>
        ) : null}
        <Button style={{ width: "fit-content" }}>
          <a
            href={href}
            // href={URL.createObjectURL(file)}
            download={file.name}
            style={{
              color: "white",
              textDecoration: "inherit",
              color: "inherit",
            }}
          >
            הורדה
          </a>
        </Button>
        <Button
          onClick={(e) => removeFile(e, file)}
          style={{ backgroundColor: "red", width: "fit-content" }}
        >
          מחיקה
        </Button>
      </div>
    </div>
  );
}

function FileName({ file, renameFile }) {
  const [width, setWidth] = React.useState(0);
  const [name, setName] = React.useState(file.name);
  const ref = React.useRef();
  const [debouncedInputValue, setDebouncedInputValue] = React.useState(
    file.name,
  );
  const didMount = React.useRef(false);

  React.useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(name);
      renameFile(file, name);
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [name]);

  React.useEffect(() => {
    setWidth(name.length + 2);
  }, []);

  const changeHandler = (evt) => {
    setWidth(evt.target.value.length + 2);
    setName(evt.target.value);
  };

  return (
    <div
      style={{
        display: "grid",
        columnGap: 16,
        alignItems: "center",
        gridTemplateColumns: "auto 1fr",
      }}
    >
      <span style={{ fontSize: 20 }}>{getFileIcon(file)}</span>
      <input
        ref={ref}
        style={{
          minWidth: 200,
          fontSize: 24,
          width: width + "ch",
          border: "none",
          background: "#ced4da",
          border: "none",
          borderBottom: "2px solid #282C34",
          padding: "4px 8px 0 8px",
        }}
        type="text"
        value={name}
        onChange={changeHandler}
      />
    </div>
  );
}

const FileDisplay = React.memo(function FileDisplay({
  file,
  allFiles,
  setAllFiles,
}) {
  if (file.type.includes("image/")) {
    return (
      <ImageFileDisplay
        file={file}
        allFiles={allFiles}
        setAllFiles={setAllFiles}
      />
    );
  }
  if (file.type.includes("video/")) {
    return <VideoFileDisplay file={file} />;
  }
  if (file.type.includes("application/pdf")) {
    return <PdfFileDisplay file={file} />;
  }
  return <OtheFileDisplay file={file} />;
});

function ImageFileDisplay({ file, allFiles, setAllFiles }) {
  const src = file.getUrl();
  console.log("@@@@@@@@@@! file\n", file);
  const rotate90 = () => {
    let r = file.rotate - 90;
    r = r % 360;
    setAllFiles(
      [...allFiles].map((f) =>
        sameFile(f, file)
          ? new AppFile({
              file: f,
              status: "FILE_CHANGED",
              dirty: true,
              rotate: r,
            })
          : f,
      ),
    );
  };

  return (
    <div style={{ display: "grid", placeItems: "center", rowGap: 16 }}>
      <TransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
        wheel={{ disabled: true }}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => {
          console.log("@@@@@@@@@@@", src);
          return (
            <React.Fragment>
              <div style={{ fontSize: "24px", display: "flex", columnGap: 16 }}>
                <ImageButton onClick={() => zoomIn()} alt="Image zoom in">
                  <IconZoomIn />
                </ImageButton>
                <ImageButton onClick={() => zoomOut()} alt="Image zoom out">
                  <IconZoomOut />
                </ImageButton>
                <ImageButton
                  onClick={() => resetTransform()}
                  alt="Reset Image Transform"
                >
                  <IconReset />
                </ImageButton>
                <ImageButton
                  onClick={() => rotate90()}
                  alt="Rotate Image counter clockwise"
                >
                  <IconRotate />
                </ImageButton>
              </div>
              <TransformComponent
                wrapperStyle={{
                  cursor: "move",
                  direction: "ltr",
                  display: "grid",
                  placeItems: "center",
                  maxHeight: "80vh",
                  maxWidth: "80vh",
                  height: "fit-content",
                  width: "100%",
                  aspectRatio: "1 / 1",
                }}
              >
                <img
                  src={
                    src.startsWith("blob")
                      ? src
                      : src + `?${new Date().toISOString()}`
                  }
                  style={{
                    // maxHeight: "100%",
                    // maxWidth: "800px",
                    // minWidth: "600px",
                    // height: "auto",
                    // objectFit: "contain",
                    maxWidth: "100%",
                    maxHeight: "80vh",
                    transform: `rotate(${file.rotate}deg)`,
                  }}
                />
              </TransformComponent>
            </React.Fragment>
          );
        }}
      </TransformWrapper>
    </div>
  );
}

function VideoFileDisplay({ file }) {
  const src = file.getUrl();
  return (
    <div
      style={{
        resize: "both",
        overflow: "auto",
        height: 500,
        position: "relative",
      }}
    >
      <video
        controls
        style={{
          maxHeight: "100%",
          width: "100%",
          height: "auto",
          objectFit: "contain",
          position: "absolute",
        }}
        src={src}
      >
        <source src={src} type={file.type} />
      </video>
    </div>
  );
}

function ImageButton({ children, onClick, alt }) {
  return (
    <button
      onClick={onClick}
      style={{
        background: "none",
        border: "2px solid #ced4da",
        width: 48,
        height: 48,
        borderRadius: 8,
        padding: 0,
        display: "grid",
        placeItems: "center",
      }}
      aria-label={alt}
    >
      {children}
    </button>
  );
}

function PdfFileDisplay({ file }) {
  const src = React.useMemo(() => {
    return file.getUrl();
  }, [file.url]);
  return <PdfFileDisplayInner src={src} />;
}

const PdfFileDisplayInner = React.memo(function PdfFileDisplayInner({ src }) {
  return (
    <embed
      style={{ width: "100%", minHeight: "80vh" }}
      src={src}
      type="application/pdf"
    ></embed>
  );
});

function OtheFileDisplay({ file }) {
  return (
    <div
      style={{
        border: "2px solid #ced4da",
        width: 150,
        height: 150,
        borderRadius: 8,
        display: "grid",
        rowGap: 8,
        placeItems: "center",
        alignContent: "center",
        padding: 16,
        margin: "0 auto",
      }}
    >
      {getFileType(file)}
      <span style={{ fontSize: 20 }}>
        <IconFile />
      </span>
    </div>
  );
}

const getFileType = (file) => {
  let fileType =
    file.name.includes(".dxf") || file.extension === "dxf"
      ? "image/x-dxf"
      : file.name.includes(".dwg") || file.extension === "dwg"
      ? "image/x-dwg"
      : file.type !== ""
      ? file.type
      : file.name.split(".")[1];
  return fileType ?? "";
};

const getFileIcon = (file) => {
  const fileType = getFileType(file);
  if (fileType === "application/pdf") {
    return <IconPdf />;
  }
  if (fileType.includes("image/") && !fileType.includes("x-")) {
    return <IconImage />;
  }
  if (fileType.includes("video/")) {
    return <IconVideo />;
  }
  return <IconFile />;
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "32px 48px",
  margin: "32px 0",
  borderWidth: 4,
  borderColor: "#ced4da",
  borderStyle: "dashed",
  borderRadius: "4px",
  backgroundColor: "#fafafa",
  color: "black",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
  width: "100%",
};

const activeStyle = {
  borderColor: "darkBlue",
};

const acceptStyle = {
  borderColor: "green",
  backgroundColor: "rgba(0, 255, 0, 0.05)",
};

const rejectStyle = {
  borderColor: "red",
};

const sameFile = (fileA /*: File */, fileB /*: File */) => {
  return (
    fileA.status === fileB.status &&
    fileA.name === fileB.name &&
    fileA.lastModified === fileB.lastModified &&
    fileA.size === fileB.size
  );
};

function SelectFiles({ allFiles, setAllFiles, addBefore = false, sellerId }) {
  const onDrop = (acceptedFiles /*: Array<AppFile> */) => {
    let newFiles = [...allFiles];
    const acceptedFilesClone = [...acceptedFiles].map((f) => {
      let extension = "";
      try {
        extension = f.name.split(".")[1];
      } catch (error) {}
      return new AppFile({
        file: f,
        status: "FILE_ACTIVE",
        sellerId,
        dirty: true,
        extension,
      });
    });
    for (const file of acceptedFilesClone) {
      let exist = false;
      const filesWithoutDeleted = allFiles.filter(
        (f) => f.status !== "FILE_DELETED",
      );
      for (const existingFile of filesWithoutDeleted) {
        if (file.status !== "FILE_CHANGED" && sameFile(existingFile, file)) {
          exist = true;
        }
      }
      if (!exist) {
        if (addBefore) {
          newFiles = [...[file], ...newFiles];
        } else {
          newFiles = [...newFiles, ...[file]];
        }
      }
    }
    setAllFiles(newFiles);
  };

  let {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    multiple: true,
    onDrop,
  });

  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );
  return (
    <div {...getRootProps({ className: "dropzone", style })}>
      <input {...getInputProps()} />
      <p style={{ margin: 0 }}>צרף/גרור לכאן קבצים</p>
    </div>
  );
}

function uuidv4() {
  return crypto.randomUUID();

  // return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
  //   (
  //     c ^
  //     (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
  //   ).toString(16),
  // );
}

function IconVideo() {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 384 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM64 288c0-17.7 14.3-32 32-32h96c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V288zM300.9 397.9L256 368V304l44.9-29.9c2-1.3 4.4-2.1 6.8-2.1c6.8 0 12.3 5.5 12.3 12.3V387.7c0 6.8-5.5 12.3-12.3 12.3c-2.4 0-4.8-.7-6.8-2.1z"></path>
    </svg>
  );
}
function IconImage() {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 384 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM64 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm152 32c5.3 0 10.2 2.6 13.2 6.9l88 128c3.4 4.9 3.7 11.3 1 16.5s-8.2 8.6-14.2 8.6H216 176 128 80c-5.8 0-11.1-3.1-13.9-8.1s-2.8-11.2 .2-16.1l48-80c2.9-4.8 8.1-7.8 13.7-7.8s10.8 2.9 13.7 7.8l12.8 21.4 48.3-70.2c3-4.3 7.9-6.9 13.2-6.9z"></path>
    </svg>
  );
}
function IconPdf() {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V304H176c-35.3 0-64 28.7-64 64V512H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM176 352h32c30.9 0 56 25.1 56 56s-25.1 56-56 56H192v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V448 368c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H192v48h16zm96-80h32c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H304c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H320v96h16zm80-112c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V432 368z"></path>
    </svg>
  );
}
function IconFile() {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 384 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48z"></path>
    </svg>
  );
}

function IconZoomIn() {
  return (
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="8"></circle>
      <line x1="21" x2="16.65" y1="21" y2="16.65"></line>
      <line x1="11" x2="11" y1="8" y2="14"></line>
      <line x1="8" x2="14" y1="11" y2="11"></line>
    </svg>
  );
}

function IconZoomOut() {
  return (
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="8"></circle>
      <line x1="21" x2="16.65" y1="21" y2="16.65"></line>
      <line x1="8" x2="14" y1="11" y2="11"></line>
    </svg>
  );
}
function IconReset() {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        strokeWidth="2"
        d="M20,8 C18.5974037,5.04031171 15.536972,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 L12,21 C16.9705627,21 21,16.9705627 21,12 M21,3 L21,9 L15,9"
      ></path>
    </svg>
  );
}

function IconRotate() {
  return (
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth="0"
      viewBox="0 0 15 15"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.59664 2.93628C7.76085 3.06401 8.00012 2.94698 8.00012 2.73895V1.99998C9.98143 2 11.1848 2.3637 11.9105 3.08945C12.6363 3.81522 13 5.0186 13 6.99998C13 7.27613 13.2239 7.49998 13.5 7.49998C13.7761 7.49998 14 7.27613 14 6.99998C14 4.9438 13.6325 3.39719 12.6176 2.38234C11.6028 1.36752 10.0562 0.999999 8.00012 0.999984V0.261266C8.00012 0.0532293 7.76085 -0.0637944 7.59664 0.063928L6.00384 1.30277C5.87516 1.40286 5.87516 1.59735 6.00384 1.69744L7.59664 2.93628ZM9.5 5H2.5C2.22386 5 2 5.22386 2 5.5V12.5C2 12.7761 2.22386 13 2.5 13H9.5C9.77614 13 10 12.7761 10 12.5V5.5C10 5.22386 9.77614 5 9.5 5ZM2.5 4C1.67157 4 1 4.67157 1 5.5V12.5C1 13.3284 1.67157 14 2.5 14H9.5C10.3284 14 11 13.3284 11 12.5V5.5C11 4.67157 10.3284 4 9.5 4H2.5Z"
        fill="currentColor"
      ></path>
    </svg>
  );
}
