import axios from "axios";

import "dotenv/config";

const baseURL = process.env.REACT_APP_BACKEND_URL;

const setAuth = () => {
  return { Authorization: "Bearer " + localStorage.getItem("token") };
};

const setSignature = () => {
  let headers = {
    // responseType: 'blob'
  };
  headers["Authorization"] = setAuth()["Authorization"];

  return headers;
};
const API = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  // transformRequest: [(data) => JSON.stringify(data.data)]
});

const redirectToLogin = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("role");
  window.location.replace(window.location.origin + "/login");
};

API.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (
      response.data &&
      response.data.success != null &&
      response.data.success == false
    ) {
      console.log("response error from interceptors", response);
      redirectToLogin();
    }
    return response;
  },
  (error) => {
    console.log("response error from interceptors", error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

const orders = {
  get: (params) => API.get("/order", { params: params, headers: setAuth() }),
  post: async (params) => {
    const rawResponse = await axios.request({
      method: "post",
      url: `${baseURL}/order`,
      data: params.formData,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },
  update: async (params) => {
    const rawResponse = await axios.request({
      method: "put",
      url: `${baseURL}/order/${params.id}`,
      data: params.formData,
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },
  sendPDF: (params) =>
    API.put(
      `/order/sendPDF/${params.id}`,
      { params: params },
      { headers: setAuth() },
    ),
  delete: (params) =>
    API.delete("/order", { params: params, headers: setAuth() }),
  // etc.
};

const items = {
  get: (params) => API.get("/item", { params: params, headers: setAuth() }),
  post: (params) =>
    API.post("/item", { params: params }, { headers: setAuth() }),
  // etc.
};

const itemTypes = {
  get: (params) => API.get("/itemType", { params: params, headers: setAuth() }),
  post: (params) =>
    API.post("/itemType", { params: params }, { headers: setAuth() }),

  // etc.
};

const companies = {
  get: (params) => API.get("/company", { params: params, headers: setAuth() }),
  post: (params) =>
    API.post("/company", { params: params }, { headers: setAuth() }),

  // etc.
};

const sellers = {
  get: (params) => API.get("/seller", { params: params, headers: setAuth() }),
  // etc.
};
const surveyors = {
  get: (params) => API.get("/surveyor", { params: params, headers: setAuth() }),
  // etc.
};

const installers = {
  get: (params) =>
    API.get("/installer", { params: params, headers: setAuth() }),
  // etc.
};

const signature = {
  get: (params) =>
    API.get("/signature", { params: params, headers: setSignature() }),
};

const login = {
  post: (params) => API.post("/login", { params }),
  // etc.
};

export default {
  orders,
  items,
  itemTypes,
  sellers,
  surveyors,
  installers,
  companies,
  signature,
  login,
};
