import React, { Component } from "react";

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  CustomInput,
  Input,
  Label,
  Row,
} from "reactstrap";

import InputText from "../../InputText/inputText";

class IslandCard extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.getDetails = this.getDetails.bind(this);

    this.state = {
      details: {
        numberOfSeats: undefined,
        numberOfChairs: undefined,
        exitInCM: 0,
        electricOutlet: "YES",
        manualWidth: "",
        manualHeight: "",
      },
    };
    this.allCompanies = [];
    this.allItems = [];
    this.allItemTypes = [];
  }

  setNames(itemTypeId, companyId, itemId) {
    const itemType = this.allItemTypes.filter(
      (itemType) => itemType.id == itemTypeId,
    )[0];
    const company = this.allCompanies.filter(
      (company) => company.id == companyId,
    )[0];
    this.itemName = itemId;

    // const item      = this.allItems.filter(item=>item.id == itemId)[0]

    this.itemTypeName = itemType ? itemType["display_name"] : undefined;
    this.companyName = company ? company["display_name"] : undefined;
    // this.itemName     = item? item['display_name']: undefined
  }

  loadCollections(dataToLoad, allItems, allCompanies, allItemTypes) {
    this.allCompanies = allCompanies;
    this.allItems = allItems;
    this.allItemTypes = allItemTypes;

    this.setNames(
      dataToLoad["item_type_id"],
      dataToLoad["company_id"],
      dataToLoad["item_id"],
    );
  }

  loadData(dataToLoad, allItems, allCompanies, allItemTypes) {
    this.loadCollections(dataToLoad, allItems, allCompanies, allItemTypes);

    console.log("island load data", dataToLoad);

    this.setState((prevState) => {
      let details = Object.assign({}, prevState.details);
      details = dataToLoad.cardDetails;
      return { details };
    });

    // this.props.onChange(eventObj);
  }

  getDetails() {
    return this.state.details;
  }

  componentDidUpdate() {
    console.log("island did update state", this.state);
    console.log("island did update props", this.props);
  }

  handleChange(event) {
    console.log("island handle change", event);

    let id = event.target.id;
    let value = event.target.value;
    let index = this.props.index;

    let eventObj = {
      target: {
        value: value,
        id: id,
        index: index,
      },
    };

    this.setState((prevState) => {
      let details = Object.assign({}, prevState.details);
      details[id] = value;
      // this.props.onChange('kitchenDetails', kitchenDetails)
      return { details };
    });

    this.props.onChange(eventObj);
  }

  render() {
    let location = this.props.location;
    let itemName = this.itemName;
    let itemTypeName = this.itemTypeName;
    let companyName = this.companyName;
    let itemType = this.itemType;

    const exitInCM = this.state.details.exitInCM;
    const numberOfSeats = this.state.details.numberOfSeats;
    const numberOfChairs = this.state.details.numberOfChairs;
    const electricOutlet = this.state.details.electricOutlet;
    const manualWidth = this.state.details.manualWidth;
    const manualHeight = this.state.details.manualHeight;
    const disabled = false;

    const labels = this.props.labels;

    let numberOfSeatsArray = [1, 2, 3, 4];
    let numberOfSeatsOptionsElements = numberOfSeatsArray.map(
      (option, index) => {
        return (
          <option key={option} value={option}>
            {option}
          </option>
        );
      },
    );
    let numberOfChairsArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    let numberOfChairsOptionsElements = numberOfChairsArray.map(
      (option, index) => {
        return (
          <option key={option} value={option}>
            {option}
          </option>
        );
      },
    );

    let electricOutletOptionsElements = labels.electricOutletOptions.map(
      (option, index) => {
        return (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        );
      },
    );

    return (
      <div>
        <Card>
          {/* <CardImg top width="100px" src="/assets/318x180.svg" alt="Card image cap" /> */}
          <CardBody>
            <CardTitle>
              {labels.item} {location + 1} | {itemTypeName} | {companyName} |{" "}
              {itemName}
            </CardTitle>
            <Container>
              <Row style={{ marginTop: 5 }}>
                <Col md={{ size: 4 }}>
                  <Label>{labels.numberOfSeats}</Label>
                </Col>
                <Col md={{ size: 3 }}>
                  <CustomInput
                    index={location}
                    type="select"
                    id="numberOfSeats"
                    name="numberOfSeatsSelect"
                    onChange={this.handleChange}
                    value={numberOfSeats}
                  >
                    <option value={undefined}>{labels.choose}</option>
                    {numberOfSeatsOptionsElements}
                  </CustomInput>
                </Col>
              </Row>

              <Row style={{ marginTop: 5 }}>
                <Col md={{ size: 4 }}>
                  <Label>{labels.numberOfChairs}</Label>
                </Col>
                <Col md={{ size: 3 }}>
                  <CustomInput
                    index={location}
                    type="select"
                    id="numberOfChairs"
                    name="numberOfChairsSelect"
                    onChange={this.handleChange}
                    value={numberOfChairs}
                  >
                    <option value={undefined}>{labels.choose}</option>
                    {numberOfChairsOptionsElements}
                  </CustomInput>
                </Col>
              </Row>

              <Row style={{ marginTop: 5 }}>
                <Col md={{ size: 4 }}>{labels.exitInCM}</Col>
                <Col md={{ size: 3 }}>
                  <Input
                    index={location}
                    type="text"
                    value={exitInCM}
                    id="exitInCM"
                    name="exitInCM"
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 5 }}>
                <Col md={{ size: 4 }}>{labels.electricOutlet}</Col>
                <Col md={{ size: 3 }}>
                  <CustomInput
                    id="electricOutlet"
                    name="electricOutletSelect"
                    index={location}
                    type="select"
                    value={electricOutlet}
                    onChange={this.handleChange}
                    disabled={disabled}
                  >
                    <option value={undefined}>{labels.choose}</option>
                    {electricOutletOptionsElements}
                  </CustomInput>
                </Col>
              </Row>
              <Row style={{ marginTop: 5 }}>
                <Col md={{ size: 4 }}>{labels.islandSizes}</Col>
                <Col md={{ size: 3 }}>
                  <InputText
                    id="manualWidth"
                    label={labels.manualWidth}
                    value={manualWidth}
                    onChange={this.handleChange}
                    disabled={disabled}
                  ></InputText>
                </Col>
                <Col md={{ size: 3 }}>
                  <InputText
                    id="manualHeight"
                    label={labels.manualHeight}
                    value={manualHeight}
                    onChange={this.handleChange}
                    disabled={disabled}
                  ></InputText>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default IslandCard;
