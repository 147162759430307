import React, { Component } from 'react'
import {Label, Input} from 'reactstrap'

class InputDate extends Component {
    constructor (props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.onChange(event);
      }
    render () {
        const value = this.props.value;
        const id    = this.props.id;
        const label = this.props.label;
        const disabled = this.props.disabled;
        const style = this.props.style;
        const isValid = this.props.valid;
        // const isInValid = this.props.invalid;

        return <div>
            <Label>{label}</Label>
            <Input 
            id={id} type="date" 
            value={value} onChange={this.handleChange} 
            disabled={disabled} style={style} 
            valid={isValid} invalid={!isValid}/>
        </div>
    }
}

export default InputDate;