import React, { Component } from "react";

import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  CustomInput,
  Label,
  Row,
  Col,
  Input
} from "reactstrap";

import Radio from "../../Radio/radio";
import InputText from "../../InputText/inputText";

class CoverCard extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.getDetails = this.getDetails.bind(this);

    this.state = {
      details: {
        height: "WINDOW",
        otherHeight: ""
      }
    };
    this.allCompanies = [];
    this.allItems     = [];
    this.allItemTypes = [];
  }

  setNames(itemTypeId, companyId, itemId){
    const itemType  = this.allItemTypes.filter(itemType=>itemType.id == itemTypeId)[0] 
    const company   = this.allCompanies.filter(company=>company.id == companyId)[0] 
    this.itemName   = itemId;
    // const item      = this.allItems.filter(item=>item.id == itemId)[0] 
   
    this.itemTypeName = itemType ? itemType['display_name'] : undefined
    this.companyName  = company? company['display_name'] : undefined
    // this.itemName     = item? item['display_name']: undefined
  }

  loadCollections(dataToLoad, allItems, allCompanies, allItemTypes){
    this.allCompanies = allCompanies;
    this.allItems     = allItems;
    this.allItemTypes = allItemTypes;

    this.setNames(dataToLoad['item_type_id'], dataToLoad['company_id'], dataToLoad['item_id']);
  }

  loadData(dataToLoad, allItems, allCompanies, allItemTypes){
    this.loadCollections(dataToLoad, allItems, allCompanies, allItemTypes);

    console.log('cover load data', dataToLoad)
    this.setState(prevState => {
      let details = Object.assign({}, prevState.details);
      details = dataToLoad.cardDetails;
      return {details};
    });
  }

  getDetails(){
    return this.state.details;
  }

  componentDidUpdate() {
    console.log("cover did update state", this.state);
    console.log("cover did update props", this.props);
  }

  handleChange(event) {
    let id = event.target.id;
    let value = event.target.value;
    let index = this.props.index;

    let eventObj = {
      target: {
        value: value,
        id: id,
        index: index
      }
    };

    this.setState(prevState => {
      let details = Object.assign({}, prevState.details); 
      details[id] = value; 
      // this.props.onChange('kitchenDetails', kitchenDetails)
      return { details }; 
    });

    this.props.onChange(eventObj);
  }

  render() {
    let location = this.props.location;
    let itemName = this.itemName;
    let companyName = this.companyName;
    let itemTypeName = this.itemTypeName;
    const height = this.state.details.height;
    const disabled = false;

    const labels = this.props.labels;

    return (
      <div>
        <Card>
          <CardBody>
            <CardTitle>
              {labels.item} {location + 1} | {itemTypeName} | {companyName} | {itemName}
            </CardTitle>

            <Row>
              <Col md={{ size: 3 }}>{labels.height}</Col>
              <Col md={{ size: 4 }}>
                <Radio
                  id="height"
                  label={undefined}
                  options={labels.heightOptions}
                  value={this.state.details.height}
                  onChange={this.handleChange}
                  disabled={disabled}
                ></Radio>
              </Col>
            </Row>
            {this.state.details.height == "OTHER" ? 
            <Row>
              <Col>
              <InputText
                id="otherHeight"
                label={undefined}
                value={this.state.details.otherHeight}
                onChange={this.handleChange}
                disabled={disabled}
              ></InputText>
            </Col> 
            </Row>
            : null}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default CoverCard;
