import React, { Component } from "react";
import {
  Form,
  Button,
  Table,
} from "reactstrap";

import api from "../../Services/api";

import AddItemDialog from "./addItemDialog";

import "./styles.css";

class ItemsManager extends Component {
  constructor(props, context) {
    super(props, context);

    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.save = this.save.bind(this);

    this.labels = Object.assign({}, require("./labels.json"));

    this.state = {
      orders: [],
      details: {
        itemTypes: [],
        companies: [],
      },
      showDialog: false,
      dialogType: undefined
    };
  }

  componentDidMount() {
    let promise1 = api.itemTypes.get({});
    let promise2 = api.companies.get({});

    Promise.all([promise1, promise2])
      .then(values => {

        this.setState(prevState => {
          let details = Object.assign([], prevState.details);
          details.itemTypes = values[0].data.item_types;
          details.companies = values[1].data.companies;
          return { details };
        });
      })
      .catch(function(error) {
      })
      .finally(function() {
        // always executed
      });
  }

  openDialog(event) {
    this.setState({ showDialog: true, dialogType: event.target.id });
  }

  closeDialog = () => {
    this.setState({ showDialog: false, dialogType: undefined });
  };

  save = data => {

    switch (data.key) {
      case "itemType":
        this.addItemType(data);
        break;
      case "company":
        this.addCompany(data);
        break;
    }
  };

  addItemType = data => {
    const itemType = {
      name: data.name,
      display_name: data.display_name,
      show_on_surveyor_pdf: data.show_on_surveyor_pdf
    };

    api.itemTypes
      .post(itemType)
      .then(response => {
        let newItemType = response.data.item_types[0]
        this.setState({ showDialog: false, dialogType: undefined });
        this.setState(prevState => {
          let details = Object.assign({}, prevState.details);
          details["itemTypes"].push(newItemType);
          return { details };
        });
      });

      
  };

  addCompany = data => {
    const company = {
      name: data.name,
      display_name: data.display_name
    };

    api.companies
      .post(company)
      .then(response => {
        let newCompany = response.data.companies[0]

        this.setState({ showDialog: false, dialogType: undefined });

        this.setState(prevState => {
          let details = Object.assign({}, prevState.details);
          details["companies"].push(newCompany);
          return { details };
        });
      });
  };

  getCompanyName = id => {
    let company = this.state.details.companies.filter(
      company => company.id == id
    );
    if (company.length > 0) {
      company = company[0]["display_name"];
    }
    return company;
  };

  getItemTypeName = id => {
    let itemType = this.state.details.itemTypes.filter(
      itemType => itemType.id == id
    );
    if (itemType.length > 0) {
      itemType = itemType[0]["display_name"];
    }
    return itemType;
  };

  render() {
    const disabled = false;
    const labels = this.labels;
    let itemTypes = this.state.details.itemTypes;
    let companies = this.state.details.companies;

    const XS = {
      width: "50px"
    };
    const S = {
      width: "80px"
    };
    const M = {
      width: "120px"
    };
    const width120 = {
      width: "120px"
    };
    const L = {
      width: "150px"
    };
    const XL = {
      width: "200px"
    };

    let itemTypesRows = itemTypes.map((itemType, index) => {
      return (
        <tr key={itemType.id}>
          <td>{itemType.name}</td>
          <td>{itemType.display_name}</td>
          <td>{itemType.show_on_surveyor_pdf ? "כן" : "לא"}</td>
          {/* <td>
          <Button
            id="itemType"
            onClick={this.openDialog}
            style={{ height: "40px"}}
          >
            {labels.edit}
          </Button>
          </td> */}
        </tr>
      );
    });

    let companiesRows = companies.map((company, index) => {
      return (
        <tr key={company.id}>
          <td>{company.name}</td>
          <td>{company.display_name}</td>
        </tr>
      );
    });

    let tableHead = (
      <thead>
        <tr>
          <th style={XS}>{labels.name}</th>
          <th style={S}>{labels.display_name}</th>
        </tr>
      </thead>
    );
    let itemTypesTableHead = (
      <thead>
        <tr>
          <th style={XS}>{labels.name}</th>
          <th style={S}>{labels.display_name}</th>
          <th style={S}>{labels.show_on_surveyor_pdf}</th>
          {/* <th style={S}>{labels.actions}</th> */}
        </tr>
      </thead>
    );

    return (
      <div
        style={{
          width: "95%",
          marginLeft: "auto",
          marginRight: "auto",
          direction: "rtl",
          textAlign: "right",
          display: "flex",

          flexDirection: "row"
        }}
      >
        <Form
          style={{
            flex: 2,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "auto",
            padding: 20
          }}
        >
          <Button
            id="itemType"
            onClick={this.openDialog}
            style={{ height: "50%", margin: "0px 10px 10px 0" }}
          >
            {labels.add} {labels.itemType}
          </Button>
          <Table hover>
            {itemTypesTableHead}
            <tbody>{itemTypesRows}</tbody>
          </Table>
        </Form>

        <Form
          style={{
            flex: 2,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "auto",
            padding: 20
          }}
        >
          <Button
            id="company"
            onClick={this.openDialog}
            style={{ height: "50%", margin: "0px 10px 10px 0" }}
          >
            {labels.add} {labels.company}
          </Button>
          <Table hover>
            {tableHead}
            <tbody>{companiesRows}</tbody>
          </Table>
        </Form>

      

        {this.state.showDialog ? (
          <AddItemDialog
            showDialog={this.state.showDialog}
            closeDialog={this.closeDialog}
            dialogType={this.state.dialogType}
            save={this.save}
          ></AddItemDialog>
        ) : null}
      </div>
    );
  }
}

export default ItemsManager;
