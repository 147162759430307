import React, { Component } from "react";

class Logout extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      details: {
       
      }
    };

    localStorage.removeItem('token')
    localStorage.removeItem('role')
    window.location.replace(window.location.origin+'/login')
  }


  render() {

    return (
      <div>

      </div>
    );
  }
}

export default Logout;
