import font from "./Heebo-Regular.ttf"
import logo from "./logo.png"

import React, { Component } from 'react'
import { StyleSheet, Font, Document, Page, View, Text, Image } from '@react-pdf/renderer'
import PDFTextFixer from "../../Services/pdfTextFixer";
import DictionaryHelper from "../../Services/dictionaryHelper";

Font.register({
  family: "Heebo",
  format: "400",
  src: font
});

const OPTIONS = "Options";

class SurveyorPDF extends Component {

  constructor(props) {

    super(props)
    this.getItemTypeDisplayName = this.getItemTypeDisplayName.bind(this);
    this.getCompanyDisplayName = this.getCompanyDisplayName.bind(this);
    this.getItemDisplayName = this.getItemDisplayName.bind(this);
    this.getPrice = this.getPrice.bind(this);
    this.prettifyPrice = this.prettifyPrice.bind(this);
    this.getItemTypeName = this.getItemTypeName.bind(this);
    this.getItemTypeShowOnSurveyorPDF = this.getItemTypeShowOnSurveyorPDF.bind(this);
    this.getLabels = this.getLabels.bind(this);


    this.tableStyle = Object.assign({}, require("./tableStyle.json"));

    this.labels = Object.assign({}, require("./labels.json"));

    this.styles = StyleSheet.create(this.tableStyle);
    
    // this.customerNoteArray = this.props.Notes['customerNote'].split('\n');

  }
  getLabels() {
   var dp = DictionaryHelper.getInstance()
   var labels = dp.getAllComponentsLabels();
    var surveyorLabels = Object.assign({}, require("./labels.json"));
    labels.Surveyor = surveyorLabels;
   return labels

  }
  getItemTypeDisplayName(id) {
    let result = this.props.allItemTypes.filter(type => type.id == id);
    if (result.length > 0) {
      return this.fixItemNoteThatContainsNumber(result[0]["display_name"]);
    }
    return id;
  }

  getItemTypeName(id){
    let result = this.props.allItemTypes.filter(type => type.id == id)
    
    if (result.length > 0) {
      return result[0]['name'];
    }

    return id;
  }
  getItemTypeShowOnSurveyorPDF(id){
    let result = this.props.allItemTypes.filter(type => type.id == id)
    
    if (result.length > 0) {
      return result[0]['show_on_surveyor_pdf'];
    }

    return id;
  }

  getCompanyDisplayName(id){
    let result = this.props.allCompanies.filter(type => type.id == id)
    var displayName = '';
    if (result.length > 0) {
      displayName = result[0]['display_name'];
    }
    return displayName;
  }

  getItemDisplayName(id){
    return id;
  }

  fixItemNoteThatContainsNumber(note) {
    let indicesAndNumbersObj = PDFTextFixer.getIndicesAndNumbers(note);
    let splittedText = PDFTextFixer.splitTextByIndices(
      note, 
      indicesAndNumbersObj.indices, 
      indicesAndNumbersObj.numbers,
      indicesAndNumbersObj.numbersSizes,
      
    )
    
    return splittedText; 
  }

  getPrice(price, length){
    return length !== 0 ?  
    Math.round(((length * price) + Number.EPSILON) * 100) / 100 : 
    Math.round(((price) + Number.EPSILON) * 100) / 100 ;
  }
  prettifyPrice(price) {
    return Math.round(((price) + Number.EPSILON) * 100) / 100 ;
  }

  render() {
    const labels = this.getLabels();

    
    const companyDetailsKeysToShow = [ 'parkingType', 'keyType', 'elevatorType',
    'cargoElevatorLocation', 'crane' , 'craneAccess', 'balconySafetyRail', 'measureStairsRotation'];
    let companyDetailsElements = companyDetailsKeysToShow.map((key, index) => {
      let value = undefined
      if (labels.Customer[key + OPTIONS]) {
        for (let i = 0; i < labels.Customer[key + OPTIONS].length; i++) {
          if (labels.Customer[key + OPTIONS][i].value === this.props.customerDetails[key]) {
            value = labels.Customer[key + OPTIONS][i].label
          }
        }
      } else {
        value = this.props.customerDetails[key]
      }
        return (
          <View style={this.styles.tableRow}>
  
          <Text style={this.styles.customerDetailsCell}>{value}</Text>
          <Text style={this.styles.customerDetailsHeader}>{labels.Customer[key]}</Text>
         
          </View>
  
        )
    })

    const customerDetailsKeysToShow = ['name', 'address', 'city', 'phoneNumber1', 'phoneNumber2', 'houseNumber', 'houseType', 'entrance', 'apartmentNumber', 'floor', 'code', 'contactForSurvey'];
    let customerDetailsElements = customerDetailsKeysToShow.map((key, index) => {
      let value = undefined
      if (labels.Customer[key + OPTIONS]) {
        for (let i = 0; i < labels.Customer[key + OPTIONS].length; i++) {
          if (labels.Customer[key + OPTIONS][i].value === this.props.customerDetails[key]) {
            value = labels.Customer[key + OPTIONS][i].label
          }
        }
      } else {
        if (key == 'name') {
          value = this.props.customerDetails['firstName'] + ' ' + this.props.customerDetails['lastName']
        } else {
          value = this.props.customerDetails[key]
        }
      }
      if (key != "houseNumber") {
        if (key != "address") {
          if (['entrance', 'apartmentNumber', 'floor', 'code'].includes(key)) {
            if (this.props.customerDetails['houseType'] == 'BUILDING') {
              return (
                <View style={this.styles.tableRow}>

                  <Text style={this.styles.customerDetailsCell}>{value}</Text>
                  <Text style={this.styles.customerDetailsHeader}>{labels.Customer[key]}</Text>

                </View>

              )
            }
          } else {
            return (
              <View style={this.styles.tableRow}>

                <Text style={this.styles.customerDetailsCell}>{value}</Text>
                <Text style={this.styles.customerDetailsHeader}>{labels.Customer[key]}</Text>

              </View>

            )
          }
        } else {
          return (
            <View style={this.styles.tableRow}>

              <Text style={this.styles.customerDetailsCell}>{this.props.customerDetails["houseNumber"]} {value}</Text>
              <Text style={this.styles.customerDetailsHeader}>{labels.Customer[key]}</Text>

            </View>
          )
        }
      }
    })

    let notesElements =  this.props.notes['surveyorNote'] ? this.props.notes['surveyorNote'].split('\n').map((line) => {
      let newNotes = this.fixItemNoteThatContainsNumber(line);
        let notes = line;
        if (newNotes !== notes) {
          notes = newNotes;
        }

        return (
          <View style={this.styles.tableRow}>
                    <Text style={this.styles.notesCell}>
{notes}</Text>
          </View>
        )
     
     
    }) : <View style={this.styles.tableRow}>
    <Text style={this.styles.notesDetails}>{labels.Notes.empty}</Text>
  </View>;

  

    let itemsElements = this.props.items.filter(item  => 
      this.getItemTypeShowOnSurveyorPDF(item.item_type_id)
    ).map((item, index) => {
      let values = {}
      let key = undefined

      let itemKeys = Object.keys(item)

      for (let i = 0; i < itemKeys.length; i++) {
        key = itemKeys[i]

        switch (key) {
          case 'price':
          case 'panel': values[key] = item[key]; break;
          case 'cardDetails':
            let cardObjectKeys = Object.keys(item.cardDetails);
            
            for (let x = 0; x < cardObjectKeys.length; x++) {
              let cardKey = cardObjectKeys[x]

              if (labels.ItemsTable[cardKey + OPTIONS]) {
                for (let y = 0; y < labels.ItemsTable[cardKey + OPTIONS].length; y++) {
                  if (labels.ItemsTable[cardKey + OPTIONS][y].value === item['cardDetails'][cardKey]) {
                    values[cardKey] = labels.ItemsTable[cardKey + OPTIONS][y].label
                  }
                }
              } else {
                if (cardKey != 'cardDetails') {
                  values[cardKey] = item.cardDetails[cardKey]
                }
              }
            };
            break;
          case 'item_type_id': values['type'] = this.getItemTypeDisplayName(item[key]); break;
          // case 'item_id': values['name'] = this.getItemDisplayName(item[key]); break;
          case 'company_id': values['company'] = this.getCompanyDisplayName(item[key]); break;
          case 'item_id': values['name'] = this.fixItemNoteThatContainsNumber(item[key]);break;
          case "details": 
            values[key] = this.fixItemNoteThatContainsNumber(item[key]);
            break;
          default:
            if (labels.ItemsTable[key + OPTIONS]) {
              for (let j = 0; j < labels.ItemsTable[key + OPTIONS].length; j++) {
                if (labels.ItemsTable[key + OPTIONS][j].value === item[key]) {
                  values[key] = labels.ItemsTable[key + OPTIONS][j].label
                }
              }
            } else {
              values[key] = item[key]
            }
        }

      }



      return (
        <View style={this.styles.itemsTableRow}>
        {/* <Text style={this.styles.itemNotesCell}>{notes}</Text> */}
        <Text style={this.styles.nicheCell}>{values['niche']}</Text>
        <Text style={this.styles.lengthCell}>{values['length']}</Text>
        {/* <Text style={this.styles.nicheCell}>{values['niche']}</Text> */}
        <Text style={this.styles.panelSizeCell}>{values['panelSize']}</Text>
        <Text style={this.styles.panelCell}>{values['panel']}</Text>
        {/* <Text style={this.styles.detailsCell}>{values['details']}</Text> */}
        {/* <Text style={this.styles.nameCell}>{values['name']}</Text> */}
        {/* <Text style={this.styles.companyCell}>{values['company']}</Text> */}
        <Text style={this.styles.itemTypeCell}>{values['type']}</Text>
        <Text style={this.styles.itemIndexCell}>{index+1}</Text>
        </View>
      )
    })

    let itemNoteElements = this.props.items.filter(item  => 
      this.getItemTypeShowOnSurveyorPDF(item.item_type_id)
    ).map((item, index) => {

      
      let notes = item["notes"];

      let lines = [];

      if (notes == "" || notes == undefined) {
        notes="אין הערה"
      } else {
         lines = notes.split("\n");
      }

      if (lines.length > 1) {
        var views = [];
        for (var nIndex = 0; nIndex < lines.length; nIndex++) {
          var line = lines[nIndex];
          var lineAfterFix = this.fixItemNoteThatContainsNumber(line);

          var indexColElement;
          var notesColElement;

          if (nIndex == 0) {
            indexColElement = <Text style={this.styles.notesIndexCell}>{index+1} </Text>
          } else {
            indexColElement = <Text style={this.styles.notesIndexCell}></Text>
          }

          notesColElement = <Text style={this.styles.notesCell}>{lineAfterFix}</Text>

          if (nIndex == 0) {
            views.push(
              <View style={this.styles.itemNoteTableRow}>
                {notesColElement}
                {indexColElement}
              </View>
            );
          } else {
            views.push(
              <View style={this.styles.itemNoteTableRow2}>
                {notesColElement}
                {indexColElement}
              </View>
            );
          }
        }
        return views;
        // lines.map(line => {
          
        // })
      } else {
        var lineAfterFix = this.fixItemNoteThatContainsNumber(notes);

        return (
          <View style={this.styles.itemNoteTableRow}>
            <Text style={this.styles.notesCell}>{lineAfterFix}</Text>
            <Text style={this.styles.notesIndexCell}>{index+1} </Text>
          </View>
  
        );
      }
    
    })


    let itemsCardsElements = this.props.items.map((item, index) => {
      let values = {}
      let key = undefined
      let cardObjectKeys = undefined ;
      let itemKeys = Object.keys(item)

      for (let i = 0; i < itemKeys.length; i++) {
        key = itemKeys[i]

        switch (key) {
          case 'price':
          case 'details':
          case 'panel': values[key] = item[key]; break;
          case 'cardDetails':
            cardObjectKeys = Object.keys(item.cardDetails);
            
            let indexOfOtherHeight = cardObjectKeys.indexOf("otherHeight");
            if (indexOfOtherHeight != -1) {
              delete cardObjectKeys[indexOfOtherHeight]
            }

            for (let x = 0; x < cardObjectKeys.length; x++) {
              let cardKey = cardObjectKeys[x]
              let cardType = cardKey == 'height' ? 'coverLabels' : 'islandLabels';

              if (labels.ItemsTable[cardType][cardKey + OPTIONS]) {
                for (let y = 0; y < labels.ItemsTable[cardType][cardKey + OPTIONS].length; y++) {
                  if (labels.ItemsTable[cardType][cardKey + OPTIONS][y].value === item['cardDetails'][cardKey]) {
                    if (cardKey == "height" && labels.ItemsTable[cardType][cardKey + OPTIONS][y].value == "OTHER") {
                      var lineAfterFix = this.fixItemNoteThatContainsNumber(item.cardDetails["otherHeight"]);

                      values[cardKey] = lineAfterFix;
                    } else {
                      values[cardKey] = labels.ItemsTable[cardType][cardKey + OPTIONS][y].label;
                    }
                  }
                }
              } else {
                if (cardKey != 'cardDetails' && cardKey != "otherHeight") {
                  values[cardKey] = item.cardDetails[cardKey]
                }
              }
            };
            break;
            case 'item_type_id': values['type'] = this.getItemTypeDisplayName(item[key]); break;
            case 'item_id': values['name'] = this.fixItemNoteThatContainsNumber(item[key]); break;
              case 'company_id': values['company'] = this.getCompanyDisplayName(item[key]); break;
              default:
              if (labels.ItemsTable[key + OPTIONS]) {
                for (let j = 0; j < labels.ItemsTable[key + OPTIONS].length; j++) {
                  if (labels.ItemsTable[key + OPTIONS][j].value === item[key]) {
                    values[key] = labels.ItemsTable[key + OPTIONS][j].label
                  }
                }
              } else {
                values[key] = item[key]
              }
        }

      }

      // let valueKeys = Object.keys(values)
      return (
        <View style={this.styles.cardsDetailsContainer}>
          {cardObjectKeys && cardObjectKeys.length > 0 ? (
            <View style={this.styles.itemCardTableRowHeader}>
              <Text style={this.styles.itemCardHeader}>
                
              </Text>
            </View>
          ) : null}
          {cardObjectKeys && cardObjectKeys.length > 0 ? cardObjectKeys.map(key => (
                <View style={this.styles.itemCardTableRow}>
                <Text style={this.styles.cardCell}> {values[key]}</Text>
                <Text style={this.styles.cardLabelCell}>{labels.ItemsTable[key == 'height' ? 'coverLabels' : 'islandLabels'][key]} </Text>
                           </View>
              ))
            : null}
        </View>
      )
    })

    let carpentryDetailsKeysToShow = ["grip"];
    if (this.props.carpentryDetails != undefined && this.props.carpentryDetails['butcher'] != undefined && this.props.carpentryDetails['butcher'] != 'NONE') {
      carpentryDetailsKeysToShow.push('butcher');
    }
    let carpentryDetailsElements = carpentryDetailsKeysToShow.map((key, index) => {
      let value = undefined;
      let label = undefined;
      if (labels.Carpentry != undefined) {
      
      if (labels.Carpentry[key + OPTIONS]) {
        for (let i = 0; i < labels.Carpentry[key + OPTIONS].length; i++) {
          if (
            labels.Carpentry[key + OPTIONS][i].value ===
            this.props.carpentryDetails[key]
          ) {
            value = labels.Carpentry[key + OPTIONS][i].label;
          }
        }
      } else {
        value = this.props.carpentryDetails[key];
      }
      label = labels.Carpentry[key];
    } 

      return (
        
        <View style={this.styles.itemCardTableRow}>
          <Text style={this.styles.carpentryCardCell}>{value}</Text>
          <Text style={this.styles.carpentryCardLabelCell}>
            {label}
          </Text>
        </View>
      );
    });


    var sinkDetailsKeysToShow = ["type", "isSingle", "installationType"];
    let sinkDetailsElements = sinkDetailsKeysToShow.map((key, index) => {
      
      let value = undefined;
      let label = undefined;
      if (labels.Sink != undefined) {
      
      if (labels.Sink[key + OPTIONS]) {
        for (let i = 0; i < labels.Sink[key + OPTIONS].length; i++) {
          if (
            labels.Sink[key + OPTIONS][i].value ===
            this.props.sinkDetails[key]
          ) {
            value = labels.Sink[key + OPTIONS][i].label;
          }
        }
      } else {
        value = this.props.sinkDetails[key];
      }
      label = labels.Sink[key];
    } 

      return (
        
        <View style={this.styles.itemCardTableRow}>
          <Text style={this.styles.cardCell}>{value}</Text>
          <Text style={this.styles.cardLabelCell}>
            {label}
          </Text>
        </View>
      );
    });


const date = new Date(this.props.customerDetails.date)

let year = date.getFullYear();
let month = date.getMonth() + 1;
let day =  date.getDate();

let dateString = date.getDate() + '-' + (date.getMonth()+1) + '-' + date.getFullYear();
let dateFixed =  `${day<10 ? 0:''}${day}-${month<10 ? 0:''}${month}-${year}`;
let totalPrice = 0; 
this.props.items.map(item => totalPrice += this.getPrice(item.price ,item.length) )
totalPrice = this.prettifyPrice(totalPrice)
let totalPriceAfterTax = this.prettifyPrice(totalPrice * 1.17)
    return <Document>
      <Page size="A4" style={this.styles.body}>
  


        <View style={this.styles.contentWrapper}>
          <View style={this.styles.contentLeft}>
            <Text style={{ textAlign: 'left' }}>{dateFixed} :{labels.Surveyor.date}</Text>
          </View>

          <View style={this.styles.logo}>
            <Image src={logo}></Image>
          </View>

          <View style={this.styles.contentRight}>
            <Text style={{ textAlign: 'right' }}>{this.props.order} :{labels.Surveyor.order}</Text>
          </View>

        </View>

       
        <View style={this.styles.contentWrapper}>
        {this.props.customerDetails['houseType'] == 'BUILDING' ? 
          <View style={this.styles.contentLeft}>
  
          <Text style={{ textAlign: 'right' }}>{'פרטי מבנה'}</Text>
         
                    <View style={this.styles.table}>
          {companyDetailsElements}
          
          </View>
          </View>
          :null}
          <View style={this.styles.contentRight}>
          <Text style={{ textAlign: 'right' }}>{labels.Surveyor.allCustomerDetails}</Text>
          <View style={this.styles.table}>
            {customerDetailsElements}
         
          </View>
        </View>
        </View>

                <View style={this.styles.separator}>
</View>
        <View style={this.styles.contentWrapper}>
          <View style={this.styles.itemsRight}>
            <Text style={{ textAlign: 'right', marginRight: '10px' }}>{labels.Surveyor.allItems}</Text>
          </View>
          </View>
        

      <View style={this.styles.table}>
        <View style={this.styles.tableRow}>
         
          
        
        {/* <Text style={this.styles.itemNotesHeader}>
            הערות
          </Text> */}
           
          
         
       
        
          <Text style={this.styles.nicheHeader}>
            פתח כיור / כיריים
          </Text>
        
          <Text style={this.styles.lengthHeader}>
          אורך )מטר(
          </Text>
{/* <Text style={this.styles.nicheHeader}>
          פתח כיור / כיריים
          </Text> */}

          <Text style={this.styles.panelSizeHeader}>
             עובי קנט )ס״מ(
          </Text>
          <Text style={this.styles.panelHeader}>
            סוג קנט
          </Text>

          {/* <Text style={this.styles.detailsHeader}>
            תיאור
          </Text> */}
          {/* <Text style={this.styles.nameHeader}>
            דגם
          </Text>

          <Text style={this.styles.companyHeader}>
            חברה
          </Text> */}
           <Text style={this.styles.itemTypeHeader}>
            סוג פריט
          </Text>
          <Text style={this.styles.itemIndexHeader}>
            #
          </Text>
        </View>
       
        {itemsElements}
           <View style={this.styles.itemsTableRow}>
          {/*  <Text style={this.styles.totalCell2}>
            {totalPrice}
          </Text>
            <Text style={this.styles.totalHeader2}>
            סה״כ לפני מע״מ
            </Text>
          </View>
          <View style={this.styles.tableRow}>       
          <Text style={this.styles.totalCell2}>
            {totalPriceAfterTax}
          </Text>
            <Text style={this.styles.totalHeader2}>
            סה״כ לתשלום
            </Text>*/}
          </View> 
      
           
          <View style={this.styles.tableNote}>
          <View style={this.styles.itemNoteHeaderTableRow}>
           <Text style={this.styles.notesHeader}>
            {labels.ItemsTable.notes}
            </Text>
          <Text style={this.styles.notesIndexHeader}>
            {labels.ItemsTable.index}
            </Text>
          </View>
          {itemNoteElements}
         </View>

        </View>
        <View style={this.styles.itemsRight}>
          <View style={this.styles.table}>
          {itemsCardsElements}
          </View>
          </View>
 
       


        

 <Text style={this.styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
{/* </Page>
<Page size="A4" style={this.styles.body}> */}
<View style={this.styles.separator}>
</View>
        <View style={this.styles.contentWrapper}>

          {/* <View style={this.styles.itemsLeft}>
            <Text style={{ textAlign: 'right', marginRight: '10px' }}>{labels.technicalDetails}</Text>
          </View> */}
          <View style={this.styles.itemsRight}>
            <Text style={{ textAlign: 'right', marginRight: '10px' }}>{labels.Surveyor.allNotes}</Text>
          </View>
        
        </View>
        <View style={this.styles.contentWrapper}>

        {/* <View style={this.styles.itemsLeft}> */}
          {/* <View style={this.styles.tableTechnicalDetails}>
            {carpentryDetailsElements}
          </View> */}
          {/* <View style={this.styles.tableTechnicalDetails}>
            {sinkDetailsElements}
          </View> */}
        {/* </View> */}
        
        <View style={this.styles.itemsRight}>
          <View style={this.styles.tableTechnicalDetails}>
            {notesElements}
          </View>
        </View>
        </View>
        

                 <View style={this.styles.separator}></View>

        <View style={this.styles.contentWrapper}>
          <View style={this.styles.itemsRight}>

            <View style={this.styles.tableTechnicalDetails}>
              <View style={this.styles.itemCardTableRowHeader}>
                <Text style={this.styles.itemCardHeader}>
                  {/* {labels.Surveyor.allSink} */}
                </Text>
              </View>
              {/* {sinkDetailsElements} */}
              {this.props.sinkDetails["supply"] == 'PICKUP' ?
                <View style={this.styles.itemCardTableRow}>
                  <Text style={this.styles.sinkPickupNote}>
                    {labels.Surveyor.sinkPickupSupply}
                  </Text>
                </View> :
                <View></View>
              }
            </View>
          </View>
        </View>
        <View style={this.styles.contentWrapper}>

<View style={this.styles.itemsRight}>

  <View style={this.styles.tableTechnicalDetails}>
    <View style={this.styles.itemCardTableRowHeader}>
      <Text style={this.styles.itemCardHeader}>
        {labels.Surveyor.allCarpentry}
      </Text>
    </View>
    {carpentryDetailsElements}
  </View>
</View>
</View>
<View style={{paddingBottom:20}}></View>

        <View style={this.styles.separator}></View>


        

     
         


        {/* <Text style={this.styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed /> */}
      </Page>
    </Document>
  }
}

export default SurveyorPDF;