import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Col,
  Button,
  Label
} from "reactstrap";

import {
  Redirect
 } from "react-router-dom";

import InputText from "../InputText/inputText";
import InputPassword from "../InputPassword/inputPassword";
import './style.css'



import api from '../../Services/api'
import aes from 'crypto-js/aes';
import GoogleBtn from "../System/Login/GoogleBtn";



class Login extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.loginAttempt = this.loginAttempt.bind(this);
    this.loginAttemptNew = this.loginAttemptNew.bind(this);
    this.getPassword = this.getPassword.bind(this);
    this.handleChangeGoogleBtn = this.handleChangeGoogleBtn.bind(this);

    this.labels = Object.assign({}, require("./labels.json"));

    this.state = {
      details: {
        email: undefined,
        password: undefined,
        redirectToDashboard: false,
        redirectToShowCustomerPage: false,
        redirectToId: 1,
        passwordSentToUser: false
      }
    };
  }

  getPassword(){
    const email    = this.state.details.email;
    api.login.post({email: email}).then(response => {
      const passwordSentToUser = response.data.passwordSentToUser;
      if (response.data.passwordSentToUser) {
        this.setState(prevState => {
          let details = Object.assign({}, prevState.details);
          details.passwordSentToUser = passwordSentToUser;
          
          return { details };
        });
      }
    });
  }
  loginAttempt() {
    const email    = this.state.details.email;
    const password = this.state.details.password;

    const pass = this.encrypt(password)
   
    api.login.post({email: email, password: pass.toString()}).then(response => {
      if (response.data.success) {

        localStorage.setItem('token', response.data.token)
        localStorage.setItem('email', email)

        this.setState(prevState => {
          let details = Object.assign({}, prevState.details);

          if (response.data.orderId) {
            localStorage.setItem('role', 'client')
            details['redirectToId'] = response.data.orderId;
            details['redirectToShowCustomerPage'] = true;
          } else {
            details['redirectToDashboard'] = true;
            localStorage.setItem('role', 'admin')
          }
          return { details };
        });
      }
    })

  }

  loginAttemptNew(email, token) {
    api.login.post({email: email, password: '', token: token}).then(response => {
      if (response.data.success) {

        localStorage.setItem('token', response.data.token)
        localStorage.setItem('email', email)

        this.setState(prevState => {
          let details = Object.assign({}, prevState.details);

          if (response.data.orderId) {
            localStorage.setItem('role', 'client')
            details['redirectToId'] = response.data.orderId;
            details['redirectToShowCustomerPage'] = true;
          } else {
            details['redirectToDashboard'] = true;
            localStorage.setItem('role', 'admin')
          }
          return { details };
        });
      }
    })
  }

  handleChangeGoogleBtn(email, token) {
    this.loginAttemptNew(email, token);
  }

  encrypt(password) {
    const IV = '5183666c72eec9e4'; 
    const KEY = 'bf3c199c2470cb477d907b1e0917c17b';
    return aes.encrypt(password, KEY, {iv:IV})
  }

  renderRedirectToDashboard = () => {
    if (this.state.details.redirectToDashboard) {
      return <Redirect to='/orders' />
    }
  }

  renderRedirectToShowCustomerPage = () => {
    if (this.state.details.redirectToShowCustomerPage) {
      return <Redirect to={'/customerForm/' + this.state.details.redirectToId} />
    }
  }

  handleChange(event) {
    let value = event.target.value;
    let id = event.target.id;

    this.setState(prevState => {
      let details = Object.assign({}, prevState.details);
      details[id] = value;
      return { details };
    });

  }

  render() {
    const disabled = false;
    const labels = this.labels;
    const passwordSentToUser = this.state.details.passwordSentToUser;

    return (
      <div className="Login">
        <Form>
        <FormGroup row>
            <Col>
              <Label>{labels.title}</Label>
            </Col>
            </FormGroup>
            <FormGroup row>
            <Col>
              <GoogleBtn 
              onChange={this.handleChangeGoogleBtn}/>
            </Col>
            </FormGroup>
          <FormGroup row>
            <Col className="AlignRight">
              <InputText
                id="email"
                label={labels["email"]}
                value={this.state.details.email}
                onChange={this.handleChange}
                disabled={disabled}
              ></InputText>
            </Col>
          </FormGroup>
            {passwordSentToUser ? 
          <FormGroup row>
          <Col className="AlignRight">
              <InputPassword
                id="password"
                label={labels["password"]}
                value={this.state.details.password}
                onChange={this.handleChange}
                disabled={disabled}
              ></InputPassword>
            </Col>
          </FormGroup>
          :null}
          <FormGroup row>
            <Col >

            {passwordSentToUser ?     <Button onClick={this.loginAttempt}>התחבר</Button>: <Button onClick={this.getPassword}>קבל קוד</Button>  }
               
            </Col>
            </FormGroup>
        </Form>
        {this.renderRedirectToDashboard()}
        {this.renderRedirectToShowCustomerPage()}
      </div>
    );
  }
}

export default Login;
