import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Col,
} from "reactstrap";

import InputText from "../InputText/inputText";
import Radio from "../Radio/radio";

class TechnicalQuestions extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    this.labels = Object.assign({}, require("./labels.json"));

    this.state = {
      details: {
        type: undefined,
        size: undefined,
      }
    };
  }


  loadData(data){
    this.setState(prevState => {
        let details = Object.assign({}, prevState.details);
        details = data;
        this.props.onChange('technicalQuestions', details)
        return {details};
      });
    }

  handleChange(event) {
    let value = event.target.value;
    let id = event.target.id;

    this.setState(prevState => {
      let details = Object.assign({}, prevState.details); 
      details[id] = value; 
      this.props.onChange('technicalQuestions', details)
      if (id == 'isArchitectOrDesignerSizeApprovalNeeded' && value == 'YES') {
      }
      return { details }; 
    });

  }

  render() {
    const disabled = false;
    const labels = this.labels;

    let elements = labels["questions"].map((question, index) =>{
      return (
        <Col key={index} md={{ size: 2 }}>
              <Radio
                id={question.key}
                label={question.label}
                options={labels["options"]}
                value={this.state.details[question.key]}
                onChange={this.handleChange}
                disabled={disabled}
              ></Radio>
              {(question.key === 'isOtherMarbleUnitsThatNeedToMeasure' || question.key === 'isCustomerRequirementAboutConnectors') 
              && this.state.details[question.key] === 'YES' ? 
                  <InputText
                  id={question.key+'OTHER'}
                  value={this.state.details[question.key+'OTHER']}
                  onChange={this.handleChange}
                  disabled={disabled}
                ></InputText> 
              : null}
            </Col>
            
      )});

    return (
      <div>
        <Form style={{ marginTop: "20px" }}>
          <FormGroup row>
            
           
          {elements}
            
          </FormGroup>
        </Form>
      </div>
    );
  }
}

export default TechnicalQuestions;
