import React from 'react';
import {
  Switch,
  Route
} from "react-router-dom";
import { Navbar, NavbarBrand } from 'reactstrap';

import './App.css';
import Login from './Components/Login/login';
import Menu from './Components/Menu/menu';
import Orders from './Components/Orders/orders';
import ItemsManager from './Components/ItemsManager/itemsManager';
import CustomerForm from './Components/CustomerForm/customerForm';
import SurveyorForm from './Components/SurveyorForm/surveyorForm';
import OfficeForm from './Components/OfficeForm/officeForm';
import Logout from './Components/Logout/logout';


function App() {
  const role = localStorage.getItem('role')
  return (
    <div className="App">
      <header className="App-header">

        
      </header>
      <div>
        {role === 'admin' ?
      <Navbar style={{direction: "rtl"}} color="light" light expand="md">
         
          <NavbarBrand href="/orders">הזמנות</NavbarBrand>
          <NavbarBrand href="/items">פריטים</NavbarBrand>
          <NavbarBrand href="/logout" style={{marginLeft:'1rem', marginRight:'auto'}}>התנתק</NavbarBrand>

        </Navbar>
        : null}

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>

 <Route key="login" path="/login">
            <Login /> 
          </Route>
          <Route key="customerForm" path="/customerForm">
            <CustomerForm /> 
          </Route>
          <Route key="officeForm" path="/officeForm">
            <OfficeForm /> 
          </Route>
          <Route key="surveyorForm" path="/surveyorForm">
            <SurveyorForm /> 
          </Route>
         
          <Route key="order" path="/order">
            <Menu />
          </Route>
           <Route key="orders" path="/orders">
            <Orders />
          </Route>
           <Route key="items" path="/items">
            <ItemsManager />
          </Route>
           <Route key="logout" path="/logout">
            <Logout />
          </Route>

         

         

        </Switch>
      </div>

    </div>
   );
  }

export default App;
