import React, { Component } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Button,  Modal, ModalHeader, ModalBody, ModalFooter, Col} from 'reactstrap';

class Signature extends Component {
  constructor (props) {
    super(props)
    this.handleSignatureChange = this.handleSignatureChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.closeSignatureDialog = this.closeSignatureDialog.bind(this)

  }
  
  sigPad = {}

  handleSignatureChange = () => {
    let dataUrl = this.sigPad.getTrimmedCanvas().toDataURL()
    this.props.onChange(dataUrl)

  }

  handleFileChange = (event) => {
    let dataUrl = URL.createObjectURL(event.target.files[0])
    this.props.onChange(dataUrl)
  }

  clear = () => {
    this.sigPad.clear()
  }

  closeSignatureDialog = () => {
    this.props.closeSignatureDialog()
}

  render () {
    return <div>
      <Modal isOpen={this.props.showSignatureDialog} style={{ direction: "rtl", width: "400px" }}>
        <ModalHeader>הוסף חתימה</ModalHeader>
        <ModalBody>
          <Col>
            <div style={{ backgroundColor: "gray", width: "340px", height: "200px", padding: "20px" }}>
              <div style={{ backgroundColor: "white", width: "300px", height: "100px" }}>
                <SignatureCanvas canvasProps={{ width: 300, height: 100, className: 'sigCanvas' }}
                  ref={(ref) => { this.sigPad = ref }} />
              </div>

              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <Button style={{ width: 60 }} onClick={this.clear}>
                  נקה
                </Button>
              </div>
            </div>
          </Col>
          <Col md={{ size: 1 }}>
            <input type="file" id="signatureFile" onChange={this.handleFileChange} />
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleSignatureChange}>שמירה</Button>
          <Button color="secondary" onClick={this.closeSignatureDialog}>ביטול</Button>
        </ModalFooter>
      </Modal>


    </div>
  }
}

export default Signature;