import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from "react-router-dom";

import * as serviceWorker from './serviceWorker';

Sentry.init({
    dsn: "https://2bd8e6615544453d86f507888f35751e@o1390045.ingest.sentry.io/6712145",
    integrations: [
    //   new Integrations.BrowserTracing(),
    ],
  
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    // tracesSampleRate: 1.0,
  });

ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));
if (window.location.hostname !== "localhost")
    console.log = () => {};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
