
class DictionaryHelper {

    
    constructor() {
        const keys = ['Carpentry', 'Customer', 'Drilling', 'Finish', 'ItemsTable', 'Notes', 'Sink', 'Stove', 'TechnicalQuestions', 'Payments']
        
        this.labels = {};

        keys.forEach(key => {
            this.labels[key] =  Object.assign({}, require(`../Components/${key}/labels.json`));
        })
        // this.sinkLabels = Object.assign({}, require("../Components/Sink/labels.json"));
        
    }

    static instance = null;
    static createInstance() {
        var object = new DictionaryHelper();
        return object;
    }
  
    static getInstance () {
        if (!DictionaryHelper.instance) {
            DictionaryHelper.instance = DictionaryHelper.createInstance();
        }
        return DictionaryHelper.instance;
    }

    getComponentLabel(componentName) {
    }

    getAllComponentsLabels() {
        return this.labels;
    }
}

export default DictionaryHelper;