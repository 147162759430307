import React, { Component } from "react";
import { Label, Input, Row, Col } from "reactstrap";

class Radio extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    let eventObj = {
      target: {
        value: event.target.value,
        id: this.props.id
      }
    };
    this.props.onChange(eventObj);
  }
  render() {
    const options = this.props.options;
    const label = this.props.label;

    let elements = options.map(({ label, value }) => {
      return (
        <Row key={value}>
          <Col>
            <Label check>
              <Input
                type="radio"
                value={value}
                checked={this.props.value === value}
                onChange={this.handleChange}
              />
              <label style={{ marginRight: "18px" }}></label>
              {label}
            </Label>
          </Col>
        </Row>
      );
    });
    
    return (
      <div>
        {label !== undefined ? <Row>
          <Col>
            <Label>{label}</Label>
          </Col>
        </Row> : null}
        {elements}
      </div>
    );
  }
}

export default Radio;
