import React, { Component } from "react";
import {
  FormGroup,
  Col,
  CustomInput,
  Label
} from "reactstrap";

import InputNumber from "../InputNumber/inputNumber";
import InputDate from "../InputDate/inputDate";
import InputText from "../InputText/inputText";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.labels = Object.assign({}, require("./labels.json"));

    this.isTouched={
      terms: false,
      total: false,
      date:false,
      number: false,
      identificationNumber: false,
      note: false
    }
  }

  handleChange(event) {
    let id = event.target.id;

    this.isTouched[id] = true;

    let eventObj = {
      target: {
        value: event.target.value,
        id: id,
        index: this.props.index
      }
    };


    this.props.onChange(eventObj);
  }

  render() {
    const disabled = false;
    const labels = this.labels;
    const index = this.props.index;
    let isValid = {};
    let isInvalid = {};

    let termsOptionsElements = labels.termsOptions.map((option, index) => {
        return (
          <option key={index} value={option.value}>{option.label}</option>
        );
      });
    
    let keys = ['terms', 'total', 'date', 'number', 'note']
      for (var i = 0; i <keys.length; i++) {
        let key = keys[i]
  
        switch(key) {
          default: 
          isValid[key]    = (this.props[key] !== ""  && this.props[key] !== undefined);
          isInvalid[key]  = (this.isTouched[key] === true && this.props[key] === "");
        }
      }

    return (
          <FormGroup row style={{width: '90%'}}>
          <Col>
          <Label>{labels.terms}</Label>
        <CustomInput value={this.props.terms} index={index} type="select" id="terms" name="terms" onChange={this.handleChange}>
        <option value={undefined}>{labels.choose}</option>
         {termsOptionsElements}
        </CustomInput>
        </Col>

        <Col>
        <InputNumber 
          value={this.props.total} 
          index={index} 
          id="total" 
          label={labels.total} 
          name="total" 
          onChange={this.handleChange}
          valid={isValid["total"]}
          invalid={isInvalid["total"]}
          >
        </InputNumber>
        </Col>

        <Col>

        <InputDate
                id="date"
                label={labels.date}
                value={this.props.date}
                index={index}
                onChange={this.handleChange}
                disabled={disabled}
                valid={isValid["date"]}
                invalid={isInvalid["date"]}
              ></InputDate>
        </Col>

        <Col>
        <InputNumber 
          value={this.props.number} 
          index={index} 
          id="number" 
          label={labels.number} 
          name="number" 
          onChange={this.handleChange}
          valid={isValid["number"]}
          invalid={isInvalid["number"]}
          >
        </InputNumber>
        </Col>

        <Col>
        <InputNumber 
          value={this.props.identificationNumber} 
          index={index} 
          id="identificationNumber" 
          label={labels.identificationNumber} 
          name="identificationNumber" 
          onChange={this.handleChange}
          valid={isValid["identificationNumber"]}
          invalid={isInvalid["identificationNumber"]}
          >
        </InputNumber>
        </Col>

        <Col>
        <InputText 
          value={this.props.note} 
          index={index} 
          id="note" 
          label={labels.note} 
          name="note" 
          onChange={this.handleChange}
          valid={isValid["note"]}
          invalid={isInvalid["note"]}
          >
        </InputText>
        </Col>

        

          </FormGroup>
    );
  }
}

export default Payment;
