import React, { Component } from "react";
import { Col, CustomInput, Form, FormGroup, Input, Label } from "reactstrap";

import { FilesSection } from "../Files/files";
import InputDate from "../InputDate/inputDate";
import InputText from "../InputText/inputText";
import Radio from "../Radio/radio";

class SinkDetails extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleFiles = this.handleFiles.bind(this);

    this.labels = Object.assign({}, require("./labels.json"));

    this.state = {
      sinkDetails: {
        type: undefined,
        color: undefined,
        isBuild: undefined,
        gripType: undefined,
        construction: undefined,
        notes: undefined,
        status: undefined,
        statusChangeDate: undefined,
        deliveredToFactoryBy: undefined,
        otherStatus: undefined,
        filesData: { files: [] },
      },
    };
    this.isDateTouched = false;
  }

  loadData(data) {
    this.setState((prevState) => {
      let sinkDetails = Object.assign({}, prevState.sinkDetails);
      sinkDetails = data;
      this.props.onChange("sinkDetails", sinkDetails);
      return { sinkDetails };
    });
  }

  handleChange(event) {
    let value = event.target.value;
    let id = event.target.id;

    if (id == "statusChangeDate") {
      this.isDateTouched = true;
    }

    this.setState((prevState) => {
      let sinkDetails = Object.assign({}, prevState.sinkDetails);
      sinkDetails[id] = value;
      this.props.onChange("sinkDetails", sinkDetails);
      return { sinkDetails };
    });
  }

  handleFiles(allFiles) {
    this.setState((prevState) => {
      let sinkDetails = Object.assign({}, prevState.sinkDetails);
      if (!sinkDetails.filesData) {
        sinkDetails.filesData = { files: [] };
      }
      sinkDetails.filesData = {
        files: allFiles,
      };
      this.props.onChange("sinkDetails", sinkDetails);
      return { sinkDetails };
    });
  }

  render() {
    const disabled = false;
    const labels = this.labels;
    let isValidDate =
      this.state.sinkDetails.statusChangeDate !== "" &&
      this.state.sinkDetails.statusChangeDate !== undefined;
    let isInvalidDate =
      this.isDateTouched === true &&
      this.state.sinkDetails.statusChangeDate === "";

    let statusOptionsElements = labels.statusOptions.map((option, index) => {
      return (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      );
    });

    let installationTypeOptionsElements = labels.installationTypeOptions.map(
      (option, index) => {
        return (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        );
      },
    );

    return (
      <div>
        <Form style={{ marginTop: "20px" }}>
          <FormGroup row>
            <Col md={{ size: 2 }}>
              <Radio
                id="supply"
                label={labels["supply"]}
                options={labels["supplyOptions"]}
                value={this.state.sinkDetails.supply}
                onChange={this.handleChange}
                disabled={disabled}
              ></Radio>
            </Col>
            <Col md={{ size: 2 }}>
              <Radio
                id="type"
                label={labels["type"]}
                options={labels["typeOptions"]}
                value={this.state.sinkDetails.type}
                onChange={this.handleChange}
                disabled={disabled}
              ></Radio>
            </Col>

            <Col md={{ size: 2 }}>
              <Radio
                id="isSingle"
                label={labels["isSingle"]}
                options={labels["isSingleOptions"]}
                value={this.state.sinkDetails.isSingle}
                onChange={this.handleChange}
                disabled={disabled}
              ></Radio>
            </Col>

            <Col md={{ size: 2 }}>
              {/* <Radio
                id="installationType"
                label={labels["installationType"]}
                options={labels["installationTypeOptions"]}
                value={this.state.sinkDetails.installationType}
                onChange={this.handleChange}
                disabled={disabled}
              ></Radio> */}
              <Label>{labels.installationType}</Label>
              <CustomInput
                id="installationType"
                type="select"
                value={this.state.sinkDetails.installationType}
                onChange={this.handleChange}
              >
                <option value={undefined}>{labels.choose}</option>
                {installationTypeOptionsElements}
              </CustomInput>
            </Col>
            <Col md={{ size: 2 }}>
              <Label>{labels.status}</Label>
              <CustomInput
                id="status"
                type="select"
                value={this.state.sinkDetails.status}
                onChange={this.handleChange}
              >
                <option value={undefined}>{labels.choose}</option>
                {statusOptionsElements}
              </CustomInput>

              {this.state.sinkDetails.status == "IN_FACTORY" ? (
                <InputText
                  id="deliveredToFactoryBy"
                  label={labels["deliveredToFactoryBy"]}
                  value={this.state.sinkDetails.deliveredToFactoryBy}
                  onChange={this.handleChange}
                  disabled={disabled}
                ></InputText>
              ) : null}

              {this.state.sinkDetails.status == "OTHER" ? (
                <InputText
                  id="otherStatus"
                  label={labels["otherStatus"]}
                  value={this.state.sinkDetails.otherStatus}
                  onChange={this.handleChange}
                  disabled={disabled}
                ></InputText>
              ) : null}

              {this.state.sinkDetails.status != undefined ? (
                <InputDate
                  id="statusChangeDate"
                  label={labels["date"]}
                  value={this.state.sinkDetails.statusChangeDate}
                  onChange={this.handleChange}
                  disabled={disabled}
                  valid={isValidDate}
                  invalid={isInvalidDate}
                ></InputDate>
              ) : null}
            </Col>
          </FormGroup>
          <FormGroup style={{ width: "40%" }}>
            <Label>{labels["notes"]}</Label>
            <Input
              id="notes"
              type="textarea"
              value={this.state.sinkDetails.notes}
              rows={5}
              onChange={this.handleChange}
            />
          </FormGroup>
        </Form>
        <FilesSection
          filesData={this.state.sinkDetails.filesData}
          handleFiles={this.handleFiles}
        />
      </div>
    );
  }
}

export default SinkDetails;
