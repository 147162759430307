import React, { Component, forwardRef } from "react";
import {
  Form,
  Button,
} from "reactstrap";

import { Redirect } from "react-router-dom";
import api from "../../Services/api";

import "./styles.css";

import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import Home from '@material-ui/icons/Home';
import LastPage from '@material-ui/icons/LastPage';
import Person from '@material-ui/icons/Person';
import Remove from '@material-ui/icons/Remove';
import Save from '@material-ui/icons/Save';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import SquareFoot from '@material-ui/icons/SquareFoot';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    Home: forwardRef((props, ref) => <Home {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Person: forwardRef((props, ref) => <Person {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Save: forwardRef((props, ref) => <Save {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    SquareFoot: forwardRef((props, ref) => <SquareFoot {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };


class Orders extends Component {
  constructor(props, context) {
    super(props, context);
    this.newOrder = this.newOrder.bind(this);

    this.editOrder = this.editOrder.bind(this);
    this.showCustomerPage = this.showCustomerPage.bind(this);
    this.showSurveyorPage = this.showSurveyorPage.bind(this);
    this.showOfficePage = this.showOfficePage.bind(this);
    this.sortByKey = this.sortByKey.bind(this);

    this.labels = Object.assign({}, require("./labels.json"));

    this.state = {
      orders: [],
      redirectToNew: false,
      redirectToEdit: false,
      redirectToShowCustomerPage: false,
      redirectToShowSurveyorPage: false,
      redirectToShowOfficePage: false,
      redirectToId: undefined
    };

  }

  renderRedirectToNew = () => {
    if (this.state.redirectToNew) {
      return <Redirect to="/order" />;
    }
  };

  renderRedirectToEdit = () => {
    if (this.state.redirectToEdit) {
      return <Redirect to={"/order/" + this.state.redirectToId} />;
    }
  };

  renderRedirectToShowCustomerPage = () => {
    if (this.state.redirectToShowCustomerPage) {
      return <Redirect to={"/customerForm/" + this.state.redirectToId} />;
    }
  };

  renderRedirectToShowOfficePage = () => {
    if (this.state.redirectToShowOfficePage) {
      return <Redirect to={"/officeForm/" + this.state.redirectToId} />;
    }
  };

  renderRedirectToShowSurveyorPage = () => {
    if (this.state.redirectToShowSurveyorPage) {
      return <Redirect to={"/surveyorForm/" + this.state.redirectToId} />;
    }
  };

 

  newOrder(event) {
    this.setState({
      redirectToNew: true
    });
  }

  editOrder(id) {
    this.setState({
      redirectToEdit: true,
      redirectToId: id
    });
  }

  showCustomerPage(id) {
    this.setState({
      redirectToShowCustomerPage: true,
      redirectToId: id
    });
  }

  showSurveyorPage(id) {
    this.setState({
      redirectToShowSurveyorPage: true,
      redirectToId: id
    });
  }

  showOfficePage(id) {
    this.setState({
      redirectToShowOfficePage: true,
      redirectToId: id
    });
  }

 sortByKey(array, key) {
    return array.sort(function(a, b) {
        var x = a[key]; var y = b[key];
        return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
}

  componentDidMount() {
    let promise1 = api.orders.get({});
    let promise2 = api.sellers.get({});

    Promise.all([promise1, promise2])
      .then(values => {

        let data = values[0].data.orders;
        let sellers = values[1].data.sellers;

        data.map((order, orderIndex) => {
          sellers.map((seller, sellerIndex) => {
            if (seller.id == order.seller_id) {
              order.seller_name = seller.name;
            }
          });

          let createdOnDate = new Date(order.created_on);
          let updatedOnDate = new Date(order.updated_on);
    
          let createdYear = createdOnDate.getFullYear();
          let createdMonth = createdOnDate.getMonth() + 1;
          let createdDay = createdOnDate.getDate();
          let createdHours = createdOnDate.getHours();
          let createdMinutes = createdOnDate.getMinutes();
          let updatedYear = updatedOnDate.getFullYear();
          let updatedMonth = updatedOnDate.getMonth() + 1;
          let updatedDay = updatedOnDate.getDate();
          let updatedHours = updatedOnDate.getHours();
          let updatedMinutes = updatedOnDate.getMinutes();
    
          let createdOnDateFixed = `
            ${createdDay < 10 ? 0 : ""}${createdDay}-
            ${createdMonth < 10 ? 0 : ""}${createdMonth}-
            ${createdYear} ${createdHours}:${createdMinutes}`;

          let updatedOnDateFixed = `${updatedDay < 10 ? 0 : ""}${updatedDay}-${updatedMonth < 10 ? 0 : ""}${updatedMonth}-${updatedYear} ${updatedHours}:${updatedMinutes}`;
          
          // order.created_on = createdOnDateFixed;
          // order.updated_on = updatedOnDateFixed;

          order.status = this.labels.statusOptions[order.status]

          if (['INTERNET', 'FACEBOOK', 'RETURN_CLIENT', 'RECOMMENDATION', 'KAMILA'].includes(order.referer)) {
            console.log('order.referer', order.referer)
            order.referer = this.labels.refererOptions[order.referer]
          }
        });
        

        this.setState(prevState => {
          let orders = Object.assign([], prevState.orders);
          orders = data;
          orders = this.sortByKey(orders,'updated_on')
          
          return { orders };
        });
      })
      .catch(function(error) {
      })
      .finally(function() {
        // always executed
      });
  }

  render() {
    const disabled = false;
    const labels = this.labels;
    let orders = this.state.orders;

    const XS = {
      width: "50px"
    };
    const S = {
      width: "80px"
    };
    const M = {
      width: "120px"
    };
    const L = {
      width: "150px"
    };
    const XL = {
      width: "200px"
    };

    let ordersRows = orders.map((order, index) => {
      // const createdOnDate = new Date(order.created_on);
      // const updatedOnDate = new Date(order.updated_on);

      // let createdYear = createdOnDate.getFullYear();
      // let createdMonth = createdOnDate.getMonth() + 1;
      // let createdDay = createdOnDate.getDate();
      // let updatedYear = updatedOnDate.getFullYear();
      // let updatedMonth = updatedOnDate.getMonth() + 1;
      // let updatedDay = updatedOnDate.getDate();

      // let createdOnDateFixed = `${createdDay < 10 ? 0 : ""}${createdDay}-${createdMonth < 10 ? 0 : ""}${createdMonth}-${createdYear}`;
      // let updatedOnDateFixed = `${updatedDay < 10 ? 0 : ""}${updatedDay}-${updatedMonth < 10 ? 0 : ""}${updatedMonth}-${updatedYear}`;

      return (
        <tr key={index}>
          <td>{order.id}</td>
          <td>{order.client_id}</td>
          <td>{order.seller_name}</td>
          <td>{order.referer}</td>
          <td>{order.created_on}</td>
          <td>{order.updated_on}</td>
          <td>{labels.statusOptions[order.status]}</td>
          <td>
            <Button
              id={order.id}
              onClick={this.editOrder}
              style={{ height: "50%", margin: "0px 15px 0 0" }}
            >
              {labels.edit}
            </Button>
            <Button
              id={order.id}
              onClick={this.showCustomerPage}
              style={{ height: "50%", margin: "0px 15px 0 0" }}
            >
              {labels.showCustomerPage}
            </Button>
            <Button
              id={order.id}
              onClick={this.showSurveyorPage}
              style={{ height: "50%", margin: "0px 15px 0 0" }}
            >
              {labels.showSurveyorPage}
            </Button>
            <Button
              id={order.id}
              onClick={this.showOfficePage}
              style={{ height: "50%", margin: "0px 15px 0 0" }}
            >
              {labels.showOfficePage}
            </Button>
          </td>
        </tr>
      );
    });

    let tableHead = (
      <thead>
        <tr>
          <th style={XS}>{labels.id}</th>
          <th style={S}>{labels.clientId}</th>
          <th style={XS}>{labels.sellerId}</th>
          <th style={XS}>{labels.referer}</th>
          <th style={XS}>{labels.createdOn}</th>
          <th style={XS}>{labels.updatedOn}</th>
          <th style={S}>{labels.status}</th>
          <th style={L}>{labels.actions}</th>
        </tr>
      </thead>
    );


let mTable = <MaterialTable
      title=""
    
      columns={[
        { title: labels.id, field: 'id', type: 'numeric' ,
        cellStyle: {
          width:'40px'
         },
         headerStyle: {
           width:'40px'
         } },
        { title: labels.clientId, field: 'client_id' },
        { title: labels.sellerId, field: 'seller_name' },
        { title: labels.referer, field: 'referer' },
        { title: labels.createdOn,
          field: 'created_on',
          cellStyle: {
            direction:'ltr',
            paddingLeft: 45
          },
          type: 'datetime',
          dateSetting: {
            locale: 'he-IL'
          }
        },
        { title: labels.updatedOn,
          field: 'updated_on',
          cellStyle: {
            direction:'ltr',
            // textAlign: 'right'
            paddingLeft: 45
          },
          type: 'datetime',
          dateSetting: {
            locale: 'he-IL'
          }
        },
        { title: labels.status, field: 'status'},
        
      ]}
      data={orders}        
      options={{
        filtering: true,
        cellStyle: {
          textAlign: 'right',
          direction: 'rtl'
        },
        headerStyle: {
          fontSize: 16,
          textAlign: 'right',
          direction: 'rtl'
        },
        filterCellStyle: {
          textAlign: 'right',
          direction: 'rtl'
        },
        actionsColumnIndex: -1,
        pageSize:10
      }}
      icons={tableIcons}
      actions={[
        {
          icon: Edit,
          tooltip: labels.edit,
          onClick: (event, rowData) => this.editOrder(rowData.id)
        },
        {
          icon: Person,
          tooltip: labels.showCustomerPage,
          onClick: (event, rowData) => this.showCustomerPage(rowData.id)
        },
        {
          icon: SquareFoot,
          tooltip: labels.showSurveyorPage,
          onClick: (event, rowData) => this.showSurveyorPage(rowData.id)
        },
        {
          icon: Home,
          tooltip: labels.showOfficePage,
          onClick: (event, rowData) => this.showOfficePage(rowData.id)
        },
 
      ]}
    />

    return (
      <div
        style={{
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          direction: "rtl",
          textAlign: "right"
        }}
      >
       {/* <Form style={{ marginTop: "20px"}}>

</Form> */}

        <Form style={{ marginTop: "20px" }}>
          <Button
            onClick={this.newOrder}
            style={{ height: "50%", margin: "0px 15px 0 0" }}
          >
            {labels.newOrder}
          </Button>
          {this.renderRedirectToNew()}
          {this.renderRedirectToEdit()}
          {this.renderRedirectToShowCustomerPage()}
          {this.renderRedirectToShowSurveyorPage()}
          {this.renderRedirectToShowOfficePage()}

          {mTable}

          {/* <Table hover style={{
          width: "95%",
          marginLeft: "auto",
          marginRight: "auto",
          direction: "rtl",
          textAlign: "right"
        }}> 
            {tableHead}
            <tbody>{ordersRows}</tbody>
          </Table> */}
        </Form>
      </div>
    );
  }
}

export default Orders;
