import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Input,
  Label
} from "reactstrap";


class AddNoteDialog extends Component {
  constructor(props) {
    super(props);
    this.closeDialog = this.closeDialog.bind(this);
    this.save = this.save.bind(this);
    this.clear = this.clear.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.labels = Object.assign({}, require("./labels.json"));

    this.state = {
      details: {
        note: "",
      }
    };
    this.noteFirstInitHappened = false;
  }

  clear = () => {
    this.loadNote("");
  }
  closeDialog = () => {
    this.props.closeDialog();
  };

  save = () => {
    let note = this.state.details.note;
    
    this.props.save({ 
        note: note,
        index: this.props.index
    });
    this.closeDialog();
  };

  loadNote = (note) => {
    this.handleChange(
      {
        target: {
          id : "note",
          value: note
        }
      })
  }

  handleChange = event => {
    let id = event.target.id;
    let value = event.target.value;

    this.setState(prevState => {
      let details = Object.assign({}, prevState.details);
      details[id] = value;
      return { details };
    });
  };

  render() {
    let note;

    if (this.state.details.note === "" && this.noteFirstInitHappened === false) {
      note = this.props.note;
      this.loadNote(note);
      this.noteFirstInitHappened = true;
    } else {
      note = this.state.details.note; 
    }
    
    const labels = this.labels;


    return (
      <div>
        <Modal
          isOpen={this.props.showDialog}
          style={{ direction: "rtl", width: "400px", textAlign: "right" }}
        >
          <ModalHeader>
            {labels["note"]}
          </ModalHeader>
          <ModalBody>
         
              <Col>
                <Label>{labels.itemType}</Label>
                <Input type="textarea" value={note} rows={5} id="note" onChange={this.handleChange} />
              </Col>
  
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.save}>
              שמירה
            </Button>
            
            <Button color="secondary" onClick={this.closeDialog}>
              ביטול
            </Button>
            <Button color="secondary" onClick={this.clear}>
              נקה
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default AddNoteDialog;
