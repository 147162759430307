import font from "./Heebo-Regular.ttf";
import logo from "./logo.png";

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { Component } from "react";

import DictionaryHelper from "../../Services/dictionaryHelper";
import PDFTextFixer from "../../Services/pdfTextFixer";

Font.register({
  family: "Heebo",
  format: "400",
  src: font,
});

const OPTIONS = "Options";

class PDF extends Component {
  constructor(props) {
    super(props);
    this.getItemTypeDisplayName = this.getItemTypeDisplayName.bind(this);
    this.getCompanyDisplayName = this.getCompanyDisplayName.bind(this);
    this.getItemDisplayName = this.getItemDisplayName.bind(this);
    this.getPrice = this.getPrice.bind(this);
    this.prettifyPrice = this.prettifyPrice.bind(this);
    this.fixItemNoteThatContainsNumber =
      this.fixItemNoteThatContainsNumber.bind(this);
    this.getLabels = this.getLabels.bind(this);

    this.styles = StyleSheet.create({
      page: {
        flexDirection: "column",
      },
      logo: {
        flex: 1,
        height: "100px",
      },
      header: {
        padding: 20,
        display: "flex",
        flexDirection: "row",
      },
      separator: {
        borderTopWidth: 2,
        borderTopColor: "#000000",
        color: "#898989",
        paddingBottom: 10,
        flexDirection: "row",
      },
      headerLeft: {
        textAlign: "right",
        flex: 2,
        fontSize: 14,
        color: "#0084B4",
        fontFamily: "Heebo",
        paddingRight: "130px",
      },
      headerCenter: {
        textAlign: "right",
        flex: 1,
        fontSize: 32,
        fontWeight: 900,
        lineHeight: 1,
        color: "#0084B4",
        fontFamily: "Heebo",
      },
      headerRight: {
        textAlign: "right",
        fontSize: 14,
        flex: 3,
        fontFamily: "Heebo",
      },
      section: {
        margin: 10,
        paddingRight: 10,
      },
      contentRight: {
        flex: 1,
        margin: 10,
        paddingRight: 10,
      },
      itemsRight: {
        flex: 1,
        // margin: 10,
        paddingRight: 10,
      },
      contentCenter: {
        flex: 1,
        margin: 10,
        // paddingRight: 10,
      },
      itemsCenter: {
        flex: 1,
        paddingRight: 10,
      },
      contentLeft: {
        flex: 1,
        margin: 10,
        paddingLeft: 10,
      },
      image: {
        width: "100px",
        height: "40px",
        textAlign: "right",
      },
      text: {
        fontFamily: "Heebo",
      },
      contentWrapper: {
        display: "flex",
        flexDirection: "row",
        marginTop: "10px",
      },
      itemsWrapper: {
        display: "flex",
        flexDirection: "row",
        // marginTop: '10px'

        // flex:1
      },
      body: {
        fontFamily: "Heebo",
        textAlign: "right",
        width: "100%",
        display: "flex",
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 65,
      },
      table: {
        display: "flex",
        marginTop: 5,
        marginBottom: 20,
        textAlign: "right",

        paddingRight: 0,
      },
      tableRow: {
        textAlign: "right",
        display: "flex",
        flexDirection: "row",
      },
      itemsTableRow: {
        textAlign: "right",
        display: "flex",
        flexDirection: "row",
        borderTopWidth: 0.5,
        borderTopColor: "gray",
      },
      tableCol: {
        textAlign: "right",
        display: "flex",
        flexDirection: "column",
        flex: 1,
      },
      itemCardTableRowHeader: {
        textAlign: "right",
        display: "flex",
        flexDirection: "row",
        borderTopColor: "gray",
        marginTop: 10,
      },
      itemCardTableRow: {
        textAlign: "right",
        display: "flex",
        flexDirection: "row",
        borderTopColor: "gray",
        borderTopWidth: 0.5,
        marginLeft: "30%",
      },
      cardCell: {
        flex: 65,
        textAlign: "right",
        fontSize: 10,

        borderLeftColor: "gray",
        paddingRight: 5,
        paddingLeft: 5,
      },
      cardLabelCell: {
        flex: 35,
        textAlign: "right",
        fontSize: 10,
        borderLeftWidth: 0.5,
        borderLeftColor: "gray",
        paddingRight: 5,
        paddingLeft: 5,
      },
      itemTypeHeader: {
        width: "15%",
        fontSize: 12,
        flex: 1,
        textAlign: "center",
        color: "#0084B4",
      },
      companyHeader: {
        width: "15%",
        fontSize: 12,
        flex: 1,
        textAlign: "center",
        color: "#0084B4",
        marginRight: "2px",
        marginLeft: "2px",
      },
      nameHeader: {
        width: "15%",
        textAlign: "center",
        flex: 1,
        fontSize: 12,
        color: "#0084B4",
        marginRight: "2px",
        marginLeft: "2px",
      },

      panelHeader: {
        width: "15%",
        textAlign: "center",
        flex: 1,
        fontSize: 12,
        color: "#0084B4",
        marginRight: "2px",
        marginLeft: "2px",
      },

      panelSizeCell: {
        flex: 1,
        textAlign: "center",
        fontSize: 10,

        borderLeftWidth: 0.5,
        borderLeftColor: "gray",
      },
      panelSizeHeader: {
        textAlign: "center",
        flex: 1,
        fontSize: 12,
        color: "#0084B4",
      },

      lengthHeader: {
        width: "15%",
        textAlign: "center",
        flex: 1,
        fontSize: 12,
        color: "#0084B4",
        marginRight: "2px",
        marginLeft: "2px",
      },
      priceHeader: {
        width: "15%",
        textAlign: "center",
        fontSize: 12,
        flex: 1,
        color: "#0084B4",
        marginRight: "2px",
        marginLeft: "2px",
      },
      totalHeader: {
        textAlign: "left",
        fontSize: 12,
        flex: 1,
        color: "#0084B4",
        marginTop: "5px",
        marginLeft: "25px",
      },
      totalHeader2: {
        textAlign: "left",
        fontSize: 12,
        flex: 10,
        color: "#0084B4",
        marginTop: "5px",
        paddingRight: "100px",
      },
      itemTypeCell: {
        width: "15%",
        flex: 1,
        textAlign: "center",
        fontSize: 10,
        borderLeftWidth: 0.5,
        borderLeftColor: "gray",
      },
      companyCell: {
        width: "15%",
        flex: 1,
        textAlign: "center",
        fontSize: 10,
        marginRight: "2px",
        marginLeft: "2px",
        borderLeftWidth: 0.5,
        borderLeftColor: "gray",
      },
      nameCell: {
        width: "15%",
        flex: 1,
        textAlign: "center",
        fontSize: 10,
        marginRight: "2px",
        marginLeft: "2px",
        borderLeftWidth: 0.5,
        borderLeftColor: "gray",
      },

      panelCell: {
        width: "15%",
        flex: 1,
        textAlign: "center",
        fontSize: 10,
        marginRight: "2px",
        marginLeft: "2px",
        borderLeftWidth: 0.5,
        borderLeftColor: "gray",
      },
      panelCell2: {
        flex: 3,
        textAlign: "center",
        fontSize: 10,
        marginRight: "2px",
        marginLeft: "2px",
      },
      lengthCell: {
        width: "15%",
        flex: 1,
        textAlign: "center",
        fontSize: 10,
        marginRight: "2px",
        marginLeft: "2px",
        borderLeftWidth: 0.5,
        borderLeftColor: "gray",
      },
      priceCell: {
        width: "15%",
        flex: 1,
        textAlign: "center",
        fontSize: 10,
        marginRight: "2px",
        marginLeft: "2px",
      },
      totalCell: {
        flex: 1,
        textAlign: "left",
        fontSize: 10,
        marginRight: "2px",
        marginLeft: "30px",
      },
      totalCell2: {
        flex: 1,
        textAlign: "left",
        fontSize: 10,
        paddingLeft: "20px",
        marginTop: "7px",
      },
      customerDetailsHeader: {
        textAlign: "right",
        fontSize: 12,
        flex: 2,
        color: "#0084B4",
        marginRight: "2px",
        marginLeft: "2px",
      },
      customerDetailsCell: {
        flex: 4,
        textAlign: "right",
        fontSize: 10,
        marginRight: "2px",
        marginLeft: "2px",
      },

      itemCardHeader: {
        textAlign: "right",
        fontSize: 12,
        flex: 3,
        color: "#0084B4",
        marginRight: "5px",
        marginLeft: "2px",
        marginTop: "3px",
      },
      itemCardCell: {
        flex: 2,
        textAlign: "right",
        fontSize: 10,
        marginRight: "15px",
        marginLeft: "2px",
      },
      notesCell: {
        textAlign: "right",
        fontSize: 10,
        flex: 1,
        marginRight: "15px",
        // marginLeft: '2px'
      },
      notesDetails: {
        textAlign: "right",
        fontSize: 10,
        flex: 1,
        paddingRight: "20px",
      },
      policyLine: {
        textAlign: "right",
        fontSize: 10,
        flex: 1,
        marginRight: "15px",
        marginBottom: "5px",
      },
      itemView: {
        marginTop: 10,
      },
      pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
      },
      paymentsTableRow: {
        textAlign: "right",
        display: "flex",
        flexDirection: "row",
        borderTopWidth: 0.5,
        borderTopColor: "gray",
      },
      paymentsHeaderTableRow: {
        textAlign: "right",
        display: "flex",
        flexDirection: "row",
      },
      paymentHeader: {
        fontSize: 12,
        flex: 2,
        textAlign: "center",
        color: "#0084B4",
      },
      paymentCell: {
        flex: 2,
        textAlign: "center",
        fontSize: 10,
        borderLeftWidth: 0.5,
        borderLeftColor: "gray",
      },
      paymentNoteHeader: {
        fontSize: 12,
        flex: 6,
        textAlign: "center",
        color: "#0084B4",
      },
      paymentNoteCell: {
        flex: 6,
        textAlign: "center",
        fontSize: 10,
      },
      paymentIndexHeader: {
        textAlign: "center",
        flex: 1,
        fontSize: 12,
        color: "#0084B4",
      },
      paymentIndexCell: {
        flex: 1,
        textAlign: "center",
        fontSize: 10,
        borderLeftWidth: 0.5,
        borderLeftColor: "gray",
      },
    });

    this.labels = Object.assign({}, require("./labels.json"));
    this.policy = Object.assign({}, require("./policy.json"));

    // this.customerNoteArray = this.props.Notes['customerNote'].split('\n');
  }

  getLabels() {
    var dp = DictionaryHelper.getInstance();
    var labels = dp.getAllComponentsLabels();
    var customerPDFLabels = Object.assign({}, require("./labels.json"));
    labels.CustomerPDF = customerPDFLabels;
    return labels;
  }

  getItemTypeDisplayName(id) {
    let result = this.props.allItemTypes.filter((type) => type.id == id);
    if (result.length > 0) {
      return this.fixItemNoteThatContainsNumber(result[0]["display_name"]);
    }
    return id;
  }

  getCompanyDisplayName(id) {
    let result = this.props.allCompanies.filter((type) => type.id == id);

    var displayName = "";
    if (result.length > 0) {
      displayName = result[0]["display_name"];
    }
    return displayName;
  }

  getItemDisplayName(id) {
    return id;
  }
  fixItemNoteThatContainsNumber(note) {
    let indicesAndNumbersObj = PDFTextFixer.getIndicesAndNumbers(note);
    let splittedText = PDFTextFixer.splitTextByIndices(
      note,
      indicesAndNumbersObj.indices,
      indicesAndNumbersObj.numbers,
      indicesAndNumbersObj.numbersSizes,
    );

    return splittedText;
  }

  getPrice(price, length) {
    return length !== 0
      ? Math.round((length * price + Number.EPSILON) * 100) / 100
      : Math.round((price + Number.EPSILON) * 100) / 100;
  }
  prettifyPrice(price) {
    return Math.round(price);
  }

  render() {
    const labels = this.getLabels();

    const companyDetailsKeysToShow = [
      "name",
      "address",
      "phone",
      "fax",
      "email",
    ];
    let companyDetailsElements = companyDetailsKeysToShow.map((key, index) => {
      let value = undefined;
      if (labels.CustomerPDF.companyDetails[key + OPTIONS]) {
        for (
          let i = 0;
          i < labels.CustomerPDF.companyDetails[key + OPTIONS].length;
          i++
        ) {
          if (
            labels.CustomerPDF.companyDetails[key + OPTIONS][i].value ===
            this.props.companyDetails[key]
          ) {
            value = labels.CustomerPDF.companyDetails[key + OPTIONS][i].label;
          }
        }
      } else {
        value = this.props.companyDetails[key];
      }
      return (
        <View style={this.styles.tableRow}>
          <Text style={this.styles.customerDetailsCell}>{value}</Text>
          <Text style={this.styles.customerDetailsHeader}>
            {labels.CustomerPDF.companyDetails[key]}
          </Text>
        </View>
      );
    });

    const customerDetailsKeysToShow = [
      "name",
      "address",
      "city",
      "phoneNumber1",
      "email",
      "houseNumber",
    ];
    let customerDetailsElements = customerDetailsKeysToShow.map(
      (key, index) => {
        let value = undefined;
        if (labels.Customer[key + OPTIONS]) {
          for (let i = 0; i < labels.Customer[key + OPTIONS].length; i++) {
            if (
              labels.Customer[key + OPTIONS][i].value ===
              this.props.customerDetails[key]
            ) {
              value = labels.Customer[key + OPTIONS][i].label;
            }
          }
        } else {
          if (key == "name") {
            value =
              this.props.customerDetails["firstName"] +
              " " +
              this.props.customerDetails["lastName"];
          } else {
            value = this.props.customerDetails[key];
          }
        }
        if (key != "houseNumber") {
          if (key != "address") {
            return (
              <View style={this.styles.tableRow}>
                <Text style={this.styles.customerDetailsCell}>{value}</Text>
                <Text style={this.styles.customerDetailsHeader}>
                  {labels.Customer[key]}
                </Text>
              </View>
            );
          } else {
            return (
              <View style={this.styles.tableRow}>
                <Text style={this.styles.customerDetailsCell}>
                  {this.props.customerDetails["houseNumber"]} {value}
                </Text>
                <Text style={this.styles.customerDetailsHeader}>
                  {labels.Customer[key]}
                </Text>
              </View>
            );
          }
        }
      },
    );

    let policyElements = this.policy["text"].map((line, index) => {
      let newIndex = index;
      if (newIndex == 4) {
        newIndex = -1;
      } else if (newIndex < 4) {
        newIndex += 1;
      }
      return (
        <View style={this.styles.tableRow}>
          <Text style={this.styles.policyLine}>
            {line} {newIndex != -1 ? "." + newIndex : null}
          </Text>
        </View>
      );
    });

    let notesElements = this.props.notes["customerNote"] ? (
      this.props.notes["customerNote"].split("\n").map((line) => {
        let newNotes = this.fixItemNoteThatContainsNumber(line);
        let notes = line;
        if (newNotes !== notes) {
          notes = newNotes;
        }
        return (
          <View style={this.styles.tableRow}>
            <Text style={this.styles.notesCell}>{notes}</Text>
          </View>
        );
      })
    ) : (
      <View style={this.styles.tableRow}>
        <Text style={this.styles.notesDetails}>{labels.Notes.empty}</Text>
      </View>
    );

    let itemsElements = this.props.items.map((item, index) => {
      let values = {};
      let key = undefined;

      let itemKeys = Object.keys(item);

      for (let i = 0; i < itemKeys.length; i++) {
        key = itemKeys[i];

        switch (key) {
          case "price":
          case "panel":
            values[key] = item[key];
            break;
          case "cardDetails":
            let cardObjectKeys = Object.keys(item.cardDetails);

            for (let x = 0; x < cardObjectKeys.length; x++) {
              let cardKey = cardObjectKeys[x];

              if (labels.ItemsTable[cardKey + OPTIONS]) {
                for (
                  let y = 0;
                  y < labels.ItemsTable[cardKey + OPTIONS].length;
                  y++
                ) {
                  if (
                    labels.ItemsTable[cardKey + OPTIONS][y].value ===
                    item["cardDetails"][cardKey]
                  ) {
                    values[cardKey] =
                      labels.ItemsTable[cardKey + OPTIONS][y].label;
                  }
                }
              } else {
                if (cardKey != "cardDetails") {
                  values[cardKey] = item.cardDetails[cardKey];
                }
              }
            }
            break;
          case "item_type_id":
            values["type"] = this.getItemTypeDisplayName(item[key]);
            break;
          case "item_id":
            values["name"] = this.fixItemNoteThatContainsNumber(item[key]);
            break;
          case "company_id":
            values["company"] = this.getCompanyDisplayName(item[key]);
            break;
          case "details":
            values[key] = this.fixItemNoteThatContainsNumber(item[key]);
            break;
          default:
            if (labels.ItemsTable[key + OPTIONS]) {
              for (
                let j = 0;
                j < labels.ItemsTable[key + OPTIONS].length;
                j++
              ) {
                if (labels.ItemsTable[key + OPTIONS][j].value === item[key]) {
                  values[key] = labels.ItemsTable[key + OPTIONS][j].label;
                }
              }
            } else {
              values[key] = item[key];
            }
        }
      }

      // let valueKeys = Object.keys(values)
      return (
        <View style={this.styles.itemsTableRow}>
          {/* <Text style={this.styles.notesCell}>{values['notes']}</Text> */}
          <Text style={this.styles.priceCell}>
            {this.getPrice(values["price"], values["length"])}
          </Text>
          <Text style={this.styles.lengthCell}>{values["length"]}</Text>
          {/* <Text style={this.styles.nicheCell}>{values['niche']}</Text> */}
          <Text style={this.styles.panelSizeCell}>{values["panelSize"]}</Text>
          <Text style={this.styles.panelCell}>{values["panel"]}</Text>
          {/* <Text style={this.styles.detailsCell}>{values['details']}</Text> */}
          <Text style={this.styles.nameCell}>{values["name"]}</Text>
          <Text style={this.styles.companyCell}>{values["company"]}</Text>
          <Text style={this.styles.itemTypeCell}>{values["type"]}</Text>
        </View>
      );
    });

    let itemsCardsElements = this.props.items.map((item, index) => {
      let values = {};
      let key = undefined;
      let cardObjectKeys = undefined;
      let itemKeys = Object.keys(item);

      for (let i = 0; i < itemKeys.length; i++) {
        key = itemKeys[i];

        switch (key) {
          case "price":
          case "details":
          case "panel":
            values[key] = item[key];
            break;
          case "cardDetails":
            cardObjectKeys = Object.keys(item.cardDetails);

            let indexOfOtherHeight = cardObjectKeys.indexOf("otherHeight");
            if (indexOfOtherHeight != -1) {
              delete cardObjectKeys[indexOfOtherHeight];
            }

            for (let x = 0; x < cardObjectKeys.length; x++) {
              let cardKey = cardObjectKeys[x];
              let cardType =
                cardKey == "height" ? "coverLabels" : "islandLabels";

              if (labels.ItemsTable[cardType][cardKey + OPTIONS]) {
                for (
                  let y = 0;
                  y < labels.ItemsTable[cardType][cardKey + OPTIONS].length;
                  y++
                ) {
                  if (
                    labels.ItemsTable[cardType][cardKey + OPTIONS][y].value ===
                    item["cardDetails"][cardKey]
                  ) {
                    if (
                      cardKey == "height" &&
                      labels.ItemsTable[cardType][cardKey + OPTIONS][y].value ==
                        "OTHER"
                    ) {
                      var lineAfterFix = this.fixItemNoteThatContainsNumber(
                        item.cardDetails["otherHeight"],
                      );

                      values[cardKey] = lineAfterFix;
                    } else {
                      values[cardKey] =
                        labels.ItemsTable[cardType][cardKey + OPTIONS][y].label;
                    }
                  }
                }
              } else {
                if (cardKey != "cardDetails" && cardKey != "otherHeight") {
                  values[cardKey] = item.cardDetails[cardKey];
                }
              }
            }
            break;
          case "item_type_id":
            values["type"] = this.getItemTypeDisplayName(item[key]);
            break;
          case "item_id":
            values["name"] = this.fixItemNoteThatContainsNumber(item[key]);
            break;
          case "company_id":
            values["company"] = this.getCompanyDisplayName(item[key]);
            break;
          default:
            if (labels.ItemsTable[key + OPTIONS]) {
              for (
                let j = 0;
                j < labels.ItemsTable[key + OPTIONS].length;
                j++
              ) {
                if (labels.ItemsTable[key + OPTIONS][j].value === item[key]) {
                  values[key] = labels.ItemsTable[key + OPTIONS][j].label;
                }
              }
            } else {
              values[key] = item[key];
            }
        }
      }

      // let valueKeys = Object.keys(values)
      return (
        // {row}
        <View style={this.styles.tableCol}>
          {cardObjectKeys && cardObjectKeys.length > 0 ? (
            <View style={this.styles.itemCardTableRowHeader}>
              <Text style={this.styles.itemCardHeader}>
                {values["name"]} | {values["company"]} | {values["type"]}
              </Text>
            </View>
          ) : null}
          {cardObjectKeys && cardObjectKeys.length > 0
            ? cardObjectKeys.map((key) => (
                <View style={this.styles.itemCardTableRow}>
                  <Text style={this.styles.cardCell}> {values[key]}</Text>
                  <Text style={this.styles.cardLabelCell}>
                    {
                      labels.ItemsTable[
                        key == "height" ? "coverLabels" : "islandLabels"
                      ][key]
                    }{" "}
                  </Text>
                </View>
              ))
            : null}
        </View>
      );
    });

    let paymentsElements = this.props.payments.payments ? (
      this.props.payments.payments.map((item, index) => {
        let values = {};
        let key = undefined;

        let itemKeys = Object.keys(item);

        for (let i = 0; i < itemKeys.length; i++) {
          key = itemKeys[i];

          switch (key) {
            case "date":
              const paymentDate = new Date(item[key]);

              let paymentYear = paymentDate.getFullYear();
              let paymentMonth = paymentDate.getMonth() + 1;
              let paymentDay = paymentDate.getDate();

              // let dateString = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
              let paymentDateFixed = `${
                paymentDay < 10 ? 0 : ""
              }${paymentDay}-${
                paymentMonth < 10 ? 0 : ""
              }${paymentMonth}-${paymentYear}`;
              values[key] = paymentDateFixed;
              break;
            default:
              if (labels.Payments[key + OPTIONS]) {
                for (
                  let j = 0;
                  j < labels.Payments[key + OPTIONS].length;
                  j++
                ) {
                  if (labels.Payments[key + OPTIONS][j].value === item[key]) {
                    values[key] = labels.Payments[key + OPTIONS][j].label;
                  }
                }
              } else {
                values[key] = item[key];
              }
          }
        }

        return (
          <View style={this.styles.paymentsTableRow}>
            <Text style={this.styles.paymentNoteCell}>{values["note"]}</Text>
            <Text style={this.styles.paymentCell}>
              {values["identificationNumber"]}
            </Text>
            <Text style={this.styles.paymentCell}>{values["number"]}</Text>
            <Text style={this.styles.paymentCell}>{values["date"]}</Text>
            <Text style={this.styles.paymentCell}>{values["total"]}</Text>
            <Text style={this.styles.paymentCell}>{values["terms"]}</Text>
            <Text style={this.styles.paymentIndexCell}>{index + 1}</Text>
          </View>
        );
      })
    ) : (
      <View style={this.styles.paymentsTableRow}>
        <Text style={this.styles.notesDetails}>{labels.Payments.empty}</Text>
      </View>
    );

    const date = new Date(this.props.customerDetails.date);

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    let dateString =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    let dateFixed = `${day < 10 ? 0 : ""}${day}-${
      month < 10 ? 0 : ""
    }${month}-${year}`;
    let totalPrice = 0;
    this.props.items.map(
      (item) => (totalPrice += this.getPrice(item.price, item.length)),
    );
    totalPrice = this.prettifyPrice(totalPrice);
    let totalPriceAfterTax = this.prettifyPrice(totalPrice * 1.17);
    return (
      <Document>
        <Page size="A4" style={this.styles.body}>
          <View style={this.styles.contentWrapper}>
            <View style={this.styles.contentLeft}>
              <Text style={{ textAlign: "left" }}>
                {dateFixed} :{labels.CustomerPDF.date}
              </Text>
            </View>

            <View style={this.styles.logo}>
              <Image src={logo}></Image>
            </View>

            <View style={this.styles.contentRight}>
              <Text style={{ textAlign: "right" }}>
                {this.props.order} :{labels.CustomerPDF.order}
              </Text>
            </View>
          </View>

          <View style={this.styles.contentWrapper}>
            <View style={this.styles.contentLeft}>
              <Text style={{ textAlign: "right" }}>
                {labels.CustomerPDF.allCompanyDetails}
              </Text>
              <View style={this.styles.table}>{companyDetailsElements}</View>
            </View>

            <View style={this.styles.contentRight}>
              <Text style={{ textAlign: "right" }}>
                {labels.CustomerPDF.allCustomerDetails}
              </Text>
              <View style={this.styles.table}>{customerDetailsElements}</View>
            </View>
          </View>

          <View style={this.styles.separator}></View>
          <View style={this.styles.contentWrapper}>
            <View style={this.styles.itemsRight}>
              <Text style={{ textAlign: "right", marginRight: "10px" }}>
                {labels.CustomerPDF.allItems}
              </Text>
            </View>
          </View>

          <View style={this.styles.table}>
            <View style={this.styles.tableRow}>
              {/*           
        <Text style={this.styles.notesHeader}>
            הערות
          </Text>
           */}

              <Text style={this.styles.priceHeader}>מחיר</Text>

              <Text style={this.styles.lengthHeader}>אורך )מטר(</Text>
              {/* <Text style={this.styles.nicheHeader}>
          פתח כיור / כיריים
          </Text> */}

              <Text style={this.styles.panelSizeHeader}>עובי קנט )ס״מ(</Text>
              <Text style={this.styles.panelHeader}>סוג קנט</Text>

              {/* <Text style={this.styles.detailsHeader}>
            תיאור
          </Text> */}
              <Text style={this.styles.nameHeader}>דגם</Text>

              <Text style={this.styles.companyHeader}>חברה</Text>
              <Text style={this.styles.itemTypeHeader}>סוג פריט</Text>
            </View>

            {itemsElements}
            <View style={this.styles.itemsTableRow}>
              <Text style={this.styles.totalCell2}>{totalPrice}</Text>
              <Text style={this.styles.totalHeader2}>סה״כ לפני מע״מ</Text>
            </View>
            <View style={this.styles.tableRow}>
              <Text style={this.styles.totalCell2}>{totalPriceAfterTax}</Text>
              <Text style={this.styles.totalHeader2}>סה״כ לתשלום</Text>
            </View>
          </View>
          <View style={this.styles.itemsRight}>
            <View style={this.styles.table}>{itemsCardsElements}</View>
          </View>

          <Text
            style={this.styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
        <Page size="A4" style={this.styles.body}>
          <View style={this.styles.separator}></View>
          <View style={this.styles.contentWrapper}>
            <View style={this.styles.itemsRight}>
              <Text style={{ textAlign: "right", marginRight: "10px" }}>
                {labels.CustomerPDF.allNotes}
              </Text>
            </View>
          </View>
          <View style={this.styles.itemsRight}>
            <View style={this.styles.table}>{notesElements}</View>
          </View>

          <View style={this.styles.separator}></View>

          <View style={this.styles.contentWrapper}>
            {/* <View style={this.styles.itemsLeft}>
            <Text style={{ textAlign: 'right', marginRight: '10px' }}>{labels.Office.technicalDetails}</Text>
          </View> */}
            <View style={this.styles.itemsRight}>
              <Text style={{ textAlign: "right", marginRight: "10px" }}>
                {labels.CustomerPDF.allPayments}
              </Text>
            </View>
          </View>

          <View style={this.styles.contentWrapper}>
            <View style={this.styles.itemsRight}>
              <View style={this.styles.table}>
                {this.props.payments.payments ? (
                  <View style={this.styles.paymentsHeaderTableRow}>
                    <Text style={this.styles.paymentNoteHeader}>הערות</Text>
                    <Text style={this.styles.paymentHeader}>מס׳ זהות</Text>
                    <Text style={this.styles.paymentHeader}>חן מס׳</Text>
                    <Text style={this.styles.paymentHeader}>תאריך</Text>
                    <Text style={this.styles.paymentHeader}>סך הכל</Text>
                    <Text style={this.styles.paymentHeader}>אופן תשלום</Text>
                    <Text style={this.styles.paymentIndexHeader}>#</Text>
                  </View>
                ) : null}

                {paymentsElements}
              </View>
            </View>
          </View>

          <View style={this.styles.separator}></View>
          <View style={this.styles.contentWrapper}>
            <View style={this.styles.itemsRight}>
              <Text
                style={{
                  textAlign: "right",
                  marginRight: "10px",
                  marginTop: "30px",
                }}
              >
                {labels.policyTerms}
              </Text>
            </View>
          </View>
          <View style={this.styles.itemsRight}>
            <View style={this.styles.table}>{policyElements}</View>
          </View>

          {this.props.customerSignature ? (
            <View style={this.styles.section}>
              <Text style={{ textAlign: "right" }}>
                {" "}
                :{labels.customerSignature}
              </Text>
              <Image
                style={this.styles.image}
                src={this.props.customerSignature}
              ></Image>
            </View>
          ) : null}
          <View style={this.styles.section}>
            {/* <Image style={this.styles.image} src={this.props.formCanvasDataUrl}></Image> */}
          </View>

          <Text
            style={this.styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
      </Document>
    );
  }
}

export default PDF;
