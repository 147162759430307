import React, { Component } from "react";
import {
  Form,
  FormGroup,
  FormText,
  Container,
  Row,
  Col,
  Button,
  CustomInput,
  Label, Table,
  CardColumns
} from "reactstrap";

import Item from "./item";
import IslandCard from "./Cards/island"
import CoverCard from "./Cards/cover"


import api from "../../Services/api";

import './Items.css';

class Items extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCard = this.handleChangeCard.bind(this);

    this.addItem = this.addItem.bind(this);
    this.addItemAfterIndex = this.addItemAfterIndex.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.getPrice = this.getPrice.bind(this);
    this.prettifyPrice = this.prettifyPrice.bind(this);
    this.loadData = this.loadData.bind(this);


    this.labels = Object.assign({}, require("./labels.json"));

    this.ItemsElements = [];
    this.CardsElements = [];

    this.state = {
      items: [
      //   {
      //   item_id: undefined,
      //   item_type_id: undefined,
      //   details: undefined,
      //   panel: undefined,
      //   length: undefined,
      //   niche: undefined,
      //   price : undefined,
      //   notes: undefined,
      //   // card : undefined,
      //   cardDetails: undefined
      // }
    ],
      cards: []
    };
    this.allItems = [];
    this.allItemTypes = [];
    this.allCompanies = [];


    let promise1 = api.items.get({});
    let promise2 = api.itemTypes.get({});
    let promise3 = api.companies.get({});
  

    Promise.all([promise1, promise2, promise3])
    .then((values) => {
      // console.log('api values', values)
          this.allItems = values[0].data.items;
          this.allItemTypes = values[1].data.item_types;
          this.allCompanies = values[2].data.companies

          let foo = {}

          for (let i = 0; i<this.allItems.length; i++ ){
            let item = this.allItems[i]
          
            if (!foo[item.item_type_id]) {
              foo[item.item_type_id] = {}
            }

            if (!foo[item.item_type_id][item.company_id]) {
              foo[item.item_type_id][item.company_id] = []
            } 
            foo[item.item_type_id][item.company_id].push(item)

          }
          console.log('foo', foo)

          this.itemsHashSet = foo;
        })
        .catch( (error) => {
          console.log(error);
        })
        .finally( () =>{
          // always executed
        });
  }

  loadData(data){
    console.log('loadData items', data)

    for(let i = 0; i < data.length; i++) {
      this.ItemsElements.push(React.createRef())
      this.CardsElements.push(React.createRef())
    }

    var itemsToSend = []
    this.setState(prevState => {
      let items = Object.assign([], prevState.items);
      let cards = Object.assign([], prevState.cards)
        items = data;

        for (let i = 0; i< data.length; i++) {
          switch (data[i].item_type_id) {
            case 2:
            console.log('item create island card')
              cards.push( new IslandCard());
              break;
    
            case 3:
            console.log('item create cover card')
              cards.push(new CoverCard());
              break;
              default : cards.push(undefined)
          }
        }

        itemsToSend = items
       
        return {items, cards};
      })

      for(let i = 0; i < data.length; i++) {
        this.ItemsElements[i].current.loadData(data[i], this.allItems, this.allCompanies, this.allItemTypes)
      }

      for(let i = 0; i < data.length; i++) {
        if (data[i].cardDetails){
        this.CardsElements[i].current.loadData(data[i], this.allItems, this.allCompanies, this.allItemTypes)
      }
      }
      this.props.onChange('items', itemsToSend)
  }
  
  handleChangeCard(event) {
    let index = event.target.index
    let value = event.target.value;
    let id = event.target.id;

    if (index == undefined) {
      console.log(`ItemsTable items, handleChangeCard index undefined`)
    } else {
      console.log(`ItemsTable items, handleChangeCard index: ${index}, value: ${value}, id: ${id}, ItemsElements: ${this.ItemsElements}`)
      this.ItemsElements[index].current.setCardData(id,value);
    }
  }

  handleChange(event) {
    let value = event.target.value;
    let id = event.target.id;
    let index = event.target.index
    console.log('items handle change')

    let itemData = value;
    let lastItemTypeId , currentItemTypeId;
    let lastCompanyId , currentCompanyId;
    let lastItemId, currentItemId;
    console.log('items item getData',itemData)

    this.setState(prevState => {
      let cards = Object.assign([], prevState.cards); 
      let items = Object.assign([], prevState.items); 

      items[index] = itemData; 

      lastItemTypeId = prevState.items[index].item_type_id
      currentItemTypeId= parseInt(itemData.item_type_id)
      
      lastCompanyId = prevState.items[index].company_id
      currentCompanyId= parseInt(itemData.company_id)
      
      lastItemId = prevState.items[index].item_id
      currentItemId= parseInt(itemData.item_id)

      if (lastItemTypeId !== currentItemTypeId){
      switch (currentItemTypeId) {
        case 2:
        console.log('item create island card')
          cards[index] =new IslandCard();
          break;

        case 3:
        console.log('item create cover card')
        cards[index] = new CoverCard();

          break;
          default : cards[index] =undefined
      }
    }
      this.props.onChange('items', items)
      return { items, cards}; 
  },()=>{
    if (this.CardsElements[index] && this.CardsElements[index].current){
    if (lastItemTypeId !== currentItemTypeId || lastCompanyId !== currentCompanyId || lastItemId !== currentItemId){
      this.CardsElements[index].current.loadCollections(itemData, this.allItems, this.allCompanies, this.allItemTypes)
    }
  }
  });


  }

  addItem(){

      let newItem = {
        item_id: undefined,
        item_type_id: undefined,
        company_id: undefined,
        details: undefined,
        panel: undefined,
        panelSize: undefined,
        length: undefined,
        niche: undefined,
        price : undefined,
        notes: undefined,
        // card : undefined,
        cardDetails: undefined
      }
      this.ItemsElements.push(React.createRef())
      this.CardsElements.push(React.createRef())


       this.setState(prevState => {
      let items = Object.assign([], prevState.items); 
      items.push(newItem); 
      this.props.onChange('items', items)

      return { items }; 
    });

  }

  addItemAfterIndex(){
var index = 0;
    let newItem = {
      item_id: undefined,
      item_type_id: undefined,
      company_id: undefined,
      details: undefined,
      panel: undefined,
      panelSize: undefined,
      length: undefined,
      niche: undefined,
      price : undefined,
      notes: undefined,
      // card : undefined,
      cardDetails: undefined
    }
    this.ItemsElements.splice(index, 0, React.createRef());
    // this.ItemsElements.push(React.createRef())
    this.CardsElements.splice(index, 0, React.createRef())


     this.setState(prevState => {
    let items = Object.assign([], prevState.items); 
    items.splice(index, 0, newItem); 
    this.props.onChange('items', items)

    return { items }; 
  });

}

    removeItem(eventIndex) {
   
        const index = parseInt(eventIndex);

        delete this.ItemsElements[index];
        delete this.CardsElements[index];

        this.setState(prevState => {
            let items = Object.assign([], prevState.items); 
            let cards = Object.assign([], prevState.cards); 

            delete items[index];
            delete cards[index];
            this.props.onChange('items', items)

            return { items, cards }; 
        });

    }


  getPrice(price, length){
    return length !== 0 ?  
    Math.round(((length * price) + Number.EPSILON) * 100) / 100 : 
    Math.round(((price) + Number.EPSILON) * 100) / 100 ;
  }

  prettifyPrice(price) {
    return Math.round(price) ;
  }

  render() {
    const disabled = false;
    const labels = this.labels;
    let items = this.state.items;
    let cards = this.state.cards;
    const XXS = {
      width: '20px'
    }
    const XS = {
      width: '50px'
    }
    const S = {
      width: '70px'
    }
    const M = {
      width: '120px'
    }
    const width120 = {
      width: '120px'
    }
    const L = {
      width: '150px'
    }
    const XL = {
      width: '200px'
    }

    let itemsRows = items.map(( item, index) => {
        return (
            <Item 
            key={index} 
            index={index} 
            onChange={this.handleChange}
            removeItem={this.removeItem}
            allItems={this.allItems}
            allItemTypes={this.allItemTypes}
            allCompanies={this.allCompanies}
            itemsHashSet={this.itemsHashSet}
            ref={this.ItemsElements[index]}/>

        );
      });

      
      let itemsCards = cards.map((card, index) =>{
        return (
          (card && card !== undefined) ? 
          (card instanceof IslandCard) ?
          <IslandCard key={index} 
          index={index} 
          location={index} 
          labels={labels.islandLabels} 
          onChange={this.handleChangeCard}
          ref={this.CardsElements[index]}
          /> : (card instanceof CoverCard) ? 
          <CoverCard key={index} 
          index={index} 
          location={index} 
          labels={labels.coverLabels} 
          onChange={this.handleChangeCard} 
          ref={this.CardsElements[index]}
          /> : null : null
        )
      });

      let tableHead =  
      <thead>
        <tr>
          <th style={XXS}>{labels.index}</th>
          <th style={M}>{labels.type}</th>
          <th style={M}>{labels.company}</th>
          <th style={S}>{labels.name}</th>
          <th style={S}>{labels.details}</th>
          <th style={S}>{labels.panel}</th>
          <th style={XXS}>{labels.panelSize}</th>
          <th style={M}>{labels.niche}</th>
          <th style={XS}>{labels.length}</th>
          {/* <th style={XS}>{labels.quantity}</th> */}
          <th style={XS}>{labels.price}</th>
          <th style={S}>{labels.notes}</th>
          <th style={XS}>{labels.total}</th>
          <th style={XS}>{labels.actions}</th>
        </tr>
      </thead>
    
    let totalPrice = 0; 
    items.map(item => totalPrice += this.getPrice(item.price ,item.length) )
    return (
        <div>
        <Form style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Table hover>
{tableHead}
<tbody>
{itemsRows}

</tbody>
        </Table>
        <Col style={{textAlign:'left', paddingLeft: '110px'}}>
        <Label style={{paddingLeft: '10px'}}>{labels.totalBeforeTax}</Label>
        <Label>{totalPrice}</Label>

        </Col>
        <Col style={{textAlign:'left', paddingLeft: '110px'}}>
        <Label style={{paddingLeft: '10px'}}>{labels.totalAfterTax}</Label>
        <Label>{this.prettifyPrice(totalPrice * 1.17)}</Label>
        </Col>
              
        
          <Button onClick={this.addItem}>{labels.addItem}</Button>
          {/* <Button onClick={this.addItemAfterIndex}>{labels.addItemAfterIndex}</Button> */}
          </Form>
<CardColumns>
          {itemsCards}
          </CardColumns>
      </div>
    );
  }
}

export default Items;
