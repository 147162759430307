import React, { Component } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import { FilesSection } from "../Files/files";

class Notes extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleFiles = this.handleFiles.bind(this);

    this.labels = Object.assign({}, require("./labels.json"));

    this.state = {
      notes: {
        surveyorNote: "",
        officeNote: "",
        factoryNote: "",
        installerNote: "",
        orderNote: "",
        customerNote: "",
        filesData: { files: [] },
      },
    };
  }

  loadData(data) {
    this.setState((prevState) => {
      let notes = Object.assign({}, prevState.notes);
      Object.keys(notes).map((key, index) => {
        notes[key] = data[key];
      });
      this.props.onChange("notes", notes);
      return { notes };
    });
  }

  handleChange(event) {
    let value = event.target.value;
    let id = event.target.id;

    this.setState((prevState) => {
      let notes = Object.assign({}, prevState.notes);
      notes[id] = value;
      this.props.onChange("notes", notes);
      return { notes };
    });
  }

  handleFiles(allFiles) {
    this.setState((prevState) => {
      let notes = Object.assign({}, prevState.notes);
      if (!notes.filesData) {
        notes.filesData = { files: [] };
      }
      notes.filesData = {
        files: allFiles,
      };
      this.props.onChange("notes", notes);
      return { notes };
    });
  }

  render() {
    const labels = this.labels;
    let notes = Object.keys(this.state.notes);

    let elements = notes.map((key, index) => {
      return (
        <FormGroup style={{ width: "80%" }}>
          <Label>{labels[key]}</Label>
          <Input
            type="textarea"
            value={this.state.notes[key]}
            rows={5}
            index={index}
            key={key}
            id={key}
            onChange={this.handleChange}
          />
        </FormGroup>
      );
    });

    return (
      <div>
        <Form style={{ marginTop: "20px" }}>
          <Row>
            <Col>
              {elements[0]}
              {elements[1]}
              {elements[2]}
            </Col>
            <Col>
              {elements[3]}
              {elements[4]}
              {elements[5]}
            </Col>
          </Row>
        </Form>
        <FilesSection
          filesData={this.state.notes.filesData}
          handleFiles={this.handleFiles}
        />
      </div>
    );
  }
}

export default Notes;
