import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Button,
} from "reactstrap";

import Drill from "./drill";

class DrillingDetails extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.addDrill = this.addDrill.bind(this);
    this.removeDrill = this.removeDrill.bind(this);


    this.labels = Object.assign({}, require("./labels.json"));

    this.state = {
      drills: [{
        type: undefined,
        place: undefined,
        location: undefined
      }]
    };
  }

  loadData(data){
    this.setState(prevState => {
        let drills = Object.assign([], prevState.drills);
        drills = data;
        this.props.onChange('drillingDetails', drills)
        return {drills};
      });
    }

  handleChange(event) {
    let value = event.target.value;
    let id = event.target.id;
    let index = event.target.index

    this.setState(prevState => {
      let drills = Object.assign([], prevState.drills); 
      drills[index][id] = value;
      this.props.onChange('drillingDetails', drills)
      return { drills }; 
  });
  }

  addDrill(){

      let newDrill = {
        type: undefined,
        place: undefined,
        location: undefined
      }
    //   this.state.drills.push(newDrill);

       this.setState(prevState => {
      let drills = Object.assign([], prevState.drills); 
      drills.push(newDrill); 
      return { drills }; 
    });

  }

    removeDrill(event) {
   
        const index = parseInt(event.target.id);

        this.setState(prevState => {
            let drills = Object.assign([], prevState.drills); 
            delete drills[index];
            return { drills }; 
        });

    }

  render() {
    const labels = this.labels;
    let drills = this.state.drills;

    let elements = drills.map(( drill, index) => {
        return (
            <FormGroup key={index} row style={{marginRight:"0px"}}>
          <Drill key={index} index={index} type={drill.type} place={drill.place} location={drill.location} onChange={this.handleChange}/>
          <Button id={index} onClick={this.removeDrill} style={{height:"50%", margin:"30px 15px 0 0"}}>{labels.removeDrill}</Button>
          </FormGroup>
        );
      });


    return (
        <div>
        <Form style={{ marginTop: "20px" }}>
        
         {elements}
              
        
          <Button onClick={this.addDrill}>{labels.addDrill}</Button>
          </Form>
      </div>
    );
  }
}

export default DrillingDetails;
