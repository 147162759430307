class PDFTextFixer {

    static getIndicesAndNumbers(text) {
      var indices = this.getNumberIndices(text);

      var numbers = [];
      
      indices.forEach(index => {
        numbers.push(this.getNumber(text, index))
      })

      var numbersSizes = [];

      numbers.forEach(number => {
        numbersSizes.push(this.getNumberDigits(number));
      })

      let indicesAndNumbersObj = {
        indices: indices,
        numbers: numbers,
        numbersSizes: numbersSizes
      }



      return indicesAndNumbersObj;
    }

    static splitTextByIndices(text, indices, numbers, numbersSizes) {
      var newText = '';
      var index = 0;
      var firstCharacterIndex = -1;

      if (text == undefined) {
        return text;
      }

      for (var i = 0; i < text.length; i++) {
        if (!this.isNumber(text[i])) {
          if (firstCharacterIndex == -1 && this.hasHebrewLetters(text[i])) {
            firstCharacterIndex = i;
          }
          newText+=text[i];
        } else {
          if (i == indices[index]) {
            if (indices[index] == 0 || firstCharacterIndex == -1) {
                           
              newText += numbers[index];

            } else {
              newText += this.flipNumber(numbers[index]);
            }
            index++
          }
        }
      }

      //this part take care of situations that includes dots between numbers.
      //like date or floats 25.10.20 or 7.5

      let newTextAfterDotFix = '';
      let currentChar;
      let currNum = '';
      let numChars = [];

      for (var i = 0; i < newText.length; i++) {
        currentChar = newText[i];

        if (this.isNumber(currentChar)) {
          currNum += currentChar;
        } else {
          if (currNum != '') {
            numChars.push(currNum);
            currNum = '';
          }
          if (currentChar == '.' || currentChar ==',') {
            numChars.push(currentChar);
          } else {
            if (numChars.length != 0) {
              let reverseNumChars = numChars.reverse();

              for (var j = 0; j< reverseNumChars.length; j++) {
                newTextAfterDotFix += reverseNumChars[j];
              }
              numChars = [];
            }
            
            newTextAfterDotFix += currentChar;
          }
          
        }
      }
      if (currNum != '') {
        numChars.push(currNum);
        currNum = '';
      }

      if (numChars.length != 0) {
        let reverseNumChars = numChars.reverse();

        for (var j = 0; j< reverseNumChars.length; j++) {
          newTextAfterDotFix += reverseNumChars[j];
        }
        numChars = [];
      }

      return newTextAfterDotFix;
    }
  
    static flipNumber(number) {
      
      var flippedNumber = number.toString().split("").reverse().join("");

      return flippedNumber
    }

    static getNumberDigits(number) {
      
        var counter = 1;

        while (parseInt(number / 10) != 0) {
          counter++;
          number /= 10;
        }
      
      return counter;      
    }

    static isTextEndsWithNumber(text) {
      
      if ( text != undefined && this.isNumber(text[text.length-1])) {
        return true;
      }
      return false;
    }

    static isTextStartsWithNumber(text) {
      if (text != undefined && this.isNumber(text[0])) {
        return true;
      }
      return false;
    }

    static isNumber(text) {
      if (!isNaN(parseInt(text) )) {
        return true;
      } else {
        return false;
      }
    }

    static getNumber(text, index) {
      var multiply = 1;
      var startIndex = -1;

      if (text == undefined){
        return text
      }
      while (this.isNumber(text[index+1])) {
        multiply *= 10;
        
        if (startIndex == -1) {
          startIndex = index;
        }
        index++;
      }

      var currentIndex = index;
      var newNum = 0;

      while (multiply != 1) {
        newNum += (parseInt(text[startIndex]) * multiply);
        startIndex++;
        multiply /= 10;
      }
      newNum += parseInt(text[currentIndex])

      return newNum;

    }

    static getIndexOfNextNumber(text, index) {
      if (text == undefined) {
        return index;
      }
      for (let i = index; i < text.length; i++) {

        if (!isNaN(parseInt(text[i]))) {
          return i;
        }
      }
    }

    static getNumberIndices(text) {
      var indices = [];
      var startIndex = -1;
if (text == undefined) {
  return indices;
}
      for (let i = 0; i < text.length; i++) {
        
        if (this.isNumber(text[i]) ) {
          if (startIndex == -1) {
            startIndex = i;
          }
         
        } else if (startIndex != -1) {
          indices.push(startIndex);
          startIndex = -1;
        }
      }
      if (startIndex != -1) {
        indices.push(startIndex);
      }
      return indices;
    }

  static hasHebrewLetters(myString){
    return /[א-ת]/.test(myString);
  }
  static hasNumber(myString) {
    return /\d/.test(myString);
  }
      
}

export default PDFTextFixer;